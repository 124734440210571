<script lang="ts" setup>
import { toRef, ref, getCurrentInstance, computed } from "vue"
import { DetailProperty } from "../../interfaces"
import MergeModal from "./MergeModal.vue"
import LinkCopyModal from "../LinkCopyModal.vue"
import SelectOptionModal from "./SelectOptionModal.vue"
import { LetterTemplate } from "@/interfaces/db"
import Flag from "@/components/Flag.vue"
import useCore from "@/plugins/use-core"
import { useI18n } from "vue-i18n"

type Params = { templateId: string; propertyId: string; locale: string }

const props = defineProps<{
  resource: DetailProperty
}>()

const resource = toRef(props, "resource")

const { axios, db } = useCore()
const { t } = useI18n()
const currentInstance = getCurrentInstance()

const teamsVisible = ref(false)
const mergeVisible = ref(false)
const lpShareVisible = ref(false)
const partnerShareVisible = ref(false)
const linkParams = ref<Params | undefined>(undefined)

const showTeamsModal = (params: Params) => {
  linkParams.value = params
  teamsVisible.value = true
}

const copyOldCrmId = () => {
  currentInstance?.appContext.config.globalProperties.$util.copy(resource.value.oldCrmId)
  App.flashy("ID successfully copied!")
}

const teamsSelected = teamId => {
  if (!linkParams.value) {
    throw new Error("linkParams is undefined")
  }

  const { templateId, propertyId, locale } = linkParams.value
  window.open(`/services/letters/${templateId}?property_id=${propertyId}&locale=${locale}&team_id=${teamId}`)
}

const toggleArchive = () => {
  resource.value.archived = !resource.value.archived
  axios.put(`/portfolio/properties/${resource.value.id}/archive`).then(_ => {
    if (resource.value.archived) {
      Turbolinks.visit("/portfolio/properties")
      App.flashy(t("property.detailDropdown.archiveSuccess"))
    } else {
      App.flashy(t("property.detailDropdown.unarchiveSuccess"))
    }
  })
}

const deleteResource = () => {
  currentInstance?.appContext.config.globalProperties.$warn(
    {
      title: t("property.detailDropdown.delete"),
      desc: t("property.detailDropdown.delWarn", { name: resource.value.name }),
      confirmButtonText: t("client.detailDropdown.delBtn"),
      redButton: true,
    },
    () => {
      axios
        .delete(`/portfolio/properties/${resource.value.id}`)
        .then(_ => {
          Turbolinks.visit("/portfolio/properties")
          App.flashy(t("property.detailDropdown.delSuccess", { name: resource.value.name }))
        })
        .catch(axios.handleError)
    }
  )
}

const letterTemplates = computed((): Array<LetterTemplate> => {
  return db.shopData.letterTemplates.filter(lt => lt.category == "exposee")
})

const teams = computed((): any[] => {
  if (db.broker.accessTeamIds && db.broker.accessTeamIds.length > 0) {
    return db.shopData.teams.filter(t => db.broker.accessTeamIds.includes(t.id))
  }
  return []
})

const translatedLocales = computed((): string[] => {
  const locales = [db.shopData.language].concat(db.shopData.supportedPropertyLocales || []).filter(Boolean)
  return resource.value.translatedLocales?.filter(locale => locales.includes(locale)) || []
})
</script>

<template>
  <dropdown button-class="details-action-link">
    <dropdown-section v-if="$db.planActive('pro', 'reshare')">
      <dropdown-item
        :title="$t('property.detailDropdown.lp')"
        :link="`/preview/properties/${resource?.id}/lp`"
        new-tab
      />
      <dropdown-item
        :title="$t('property.detailDropdown.report')"
        :link="`/preview/properties/${resource?.id}/report`"
        new-tab
      />
      <dropdown-item
        v-if="$db.featureActive('property_statistics')"
        :title="$t('property.detailDropdown.statistics')"
        :link="`/portfolio/properties/${resource?.id}/preview?template_type=statistics`"
        new-tab
      />
      <dropdown-item :title="$t('property.detailDropdown.lpShare')" @click="lpShareVisible = true" />
      <dropdown-item :title="$t('property.detailDropdown.propertyShare')" @click="partnerShareVisible = true" />
    </dropdown-section>
    <dropdown-section v-if="$db.planActive('pro', 'reshare')">
      <dropdown-item :link="`/preview/properties/${resource?.id}/print?locale=${translatedLocales[0]}`" new-tab>
        <template v-slot:title>
          <div class="relative">
            {{ $t("property.detailDropdown.printExposee") }}
            <hover-actions v-if="translatedLocales.length > 1" right="0" top="0">
              <ps-button
                size="small"
                class="ps-hover border border-gray-300 border-solid"
                v-for="locale in translatedLocales.slice(0, 2)"
                :key="locale"
                :visit="`/preview/properties/${resource.id}/print?locale=${locale}`"
                new-tab
              >
                <flag v-if="locale" :locale="locale" />
              </ps-button>

              <nice-popover
                v-if="translatedLocales.length > 2"
                ref="printexposeepopover"
                placement="bottom"
                width="150"
                trigger="hover"
                transition="none"
                popper-class="locale-popover"
              >
                <template #reference>
                  <hover-action size="sm" icon="ellipsis-h" />
                </template>
                <div class="flex flex-col items-center justify-center p-2">
                  <ps-button
                    v-for="locale in translatedLocales.slice(2)"
                    :key="locale"
                    :visit="`/preview/properties/${resource.id}/print?locale=${locale}`"
                    new-tab
                    style="width: 30px"
                  >
                    <flag v-if="locale" :locale="locale" />
                  </ps-button>
                </div>
              </nice-popover>
            </hover-actions>
          </div>
        </template>
      </dropdown-item>
      <dropdown-item
        :title="$t('property.detailDropdown.onepager')"
        :link="`/preview/properties/${resource.id}/print?one_pager=true`"
        new-tab
      />
      <template v-for="template in letterTemplates" :key="template.id">
        <dropdown-item
          :link="
            teams && teams.length > 1
              ? undefined
              : `/services/letters/${template.id}?property_id=${resource.id}&locale=${translatedLocales[0]}`
          "
          v-on:click="
            teams && teams.length > 1
              ? showTeamsModal({
                  templateId: String(template.id),
                  propertyId: String(resource.id),
                  locale: linkParams?.locale || '',
                })
              : undefined
          "
          new-tab
        >
          <template v-slot:title>
            <div class="relative">
              {{ template.name }}
              <hover-actions v-if="translatedLocales.length > 1" right="0" top="0">
                <ps-button
                  size="small"
                  class="ps-hover border border-gray-300 border-solid"
                  v-for="locale in translatedLocales.slice(0, 2)"
                  :key="`${template.id}-${locale}`"
                  :visit="
                    teams && teams.length > 1
                      ? undefined
                      : `/services/letters/${template.id}?property_id=${resource.id}&locale=${locale}`
                  "
                  new-tab
                >
                  <flag v-if="locale" :locale="locale" />
                </ps-button>
                <nice-popover
                  v-if="translatedLocales.length > 2"
                  ref="lettertemplatepopover"
                  placement="bottom"
                  width="20"
                  trigger="hover"
                  transition="none"
                  popper-class="locale-popover"
                >
                  <template #reference>
                    <hover-action size="sm" icon="ellipsis-h" />
                  </template>
                  <div class="flex flex-col items-center justify-center p-2">
                    <ps-button
                      v-for="locale in translatedLocales.slice(2)"
                      :key="`${template.id}-${locale}`"
                      :visit="
                        teams && teams.length > 1
                          ? undefined
                          : `/services/letters/${template.id}?property_id=${resource.id}&locale=${locale}`
                      "
                      style="width: 30px"
                      new-tab
                    >
                      <flag v-if="locale" :locale="locale" />
                    </ps-button>
                  </div>
                </nice-popover>
              </hover-actions>
            </div>
          </template>
        </dropdown-item>
      </template>
    </dropdown-section>
    <dropdown-section v-if="resource.editable">
      <dropdown-item :title="$t('property.detailDropdown.copy')" :link="`/portfolio/properties/${resource.id}/copy`" />
      <dropdown-item
        :title="$t('property.detailDropdown.create_variant')"
        :link="`/portfolio/properties/${resource.id}/new_variant`"
        v-if="$db.planActive('pro', 'reshare')"
      />
      <dropdown-item
        :title="$t('property.detailDropdown.merge')"
        v-if="$db.planActive('pro', 'reshare')"
        @click="mergeVisible = true"
      />
    </dropdown-section>
    <dropdown-section v-if="resource.editable">
      <dropdown-item
        :title="resource.archived ? $t('property.detailDropdown.unarchive') : $t('property.detailDropdown.archive')"
        @click="toggleArchive"
      />
      <dropdown-item
        v-if="$db.broker.canDeleteProperties"
        :title="$t('property.detailDropdown.delete')"
        @click="deleteResource"
        class="text-danger"
      />
    </dropdown-section>
    <dropdown-section v-if="$root?.dbackdoor && resource.oldCrmId">
      <tooltip :delay="500">
        <dropdown-item title="Copy old CRM-ID" @click="copyOldCrmId" />
        <template v-slot:content>
          <span>
            ID:
            <span class="font-mono">{{ resource.oldCrmId }}</span>
          </span>
        </template>
      </tooltip>
    </dropdown-section>

    <merge-modal v-model:visible="mergeVisible" :propertyId="resource?.id" :propertyName="resource?.name" />
    <link-copy-modal
      :model-value="lpShareVisible"
      @update:model-value="value => (lpShareVisible = value)"
      :title="`${$t('property.detailDropdown.lpPublic')} – ${resource?.name}`"
      :url="resource?.lpShareUrl"
    />
    <link-copy-modal
      :model-value="partnerShareVisible"
      @update:model-value="value => (partnerShareVisible = value)"
      :title="`${$t('property.detailDropdown.partner')} – ${resource?.name}`"
      :url="resource?.partnerShareUrl"
    />
    <select-option-modal
      :model-value="teamsVisible"
      :title="$t('property.detailDropdown.teamSelect.title')"
      :label="$t('property.detailDropdown.teamSelect.label')"
      :button="$t('property.detailDropdown.teamSelect.title')"
      :text="$t('property.detailDropdown.teamSelect.text')"
      v-if="teams && teams.length > 1"
      :options="teams"
      @submit="teamsSelected"
    />
  </dropdown>
</template>

<style>
.locale-popover {
  min-width: 43px !important;
  width: 43px !important;
  padding-left: 3px;
}

.ps-hover:hover {
  background-color: #ededed;
}
</style>
