<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    visible: boolean
    link: string
    title: string
  }>(),
  {
    visible: false,
  }
)
const emit = defineEmits(["update:visible"])
</script>
<template>
  <nice-dialog
    :model-value="props.visible"
    @update:model-value="emit('update:visible', $event)"
    @close="emit('update:visible', false)"
    :title="props.title"
    :fullscreen="true"
    custom-class="iframe-modal"
    append-to-body
  >
    <iframe :src="props.link" class="h-full w-full" :title="props.title" />
  </nice-dialog>
</template>
