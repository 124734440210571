<template>
  <nice-popover
    ref="popover"
    placement="bottom"
    :show-arrow="false"
    trigger="click"
    :width="width"
    :hide-after="0"
    transition="none"
    @show="$emit('open')"
    :disabled="disabled || undefined"
    :append-to-body="appendToBody"
    v-bind="$attrs"
  >
    <template #reference>
      <slot name="reference" v-bind="{ referenceClass: disabled ? 'disabled' : '' }">
        <button :disabled="disabled || undefined" :class="[buttonClass, disabled ? 'disabled' : '']" @click.prevent>
          <slot name="title">
            <fa-icon name="ellipsis-h" />
          </slot>
        </button>
      </slot>
    </template>
    <main @click="hide">
      <slot />
    </main>
  </nice-popover>
</template>

<script>
export default {
  props: {
    buttonClass: {
      type: String,
      default: "tableActions-action",
    },
    width: {
      type: String,
      default: "240",
    },
    appendToBody: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    hide() {
      this.$refs?.popover?.hide()
    },
  },
}
</script>

<style scoped>
main {
  max-height: 80vh;
  overflow: auto;
}
</style>
