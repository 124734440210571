<template xmlns="http://www.w3.org/1999/html">
  <div class="app-panel">
    <page-header>
      <template v-slot:leftMenu>
        <a href="#" @click.prevent="close" class="pane-button">
          <fa-icon name="times" />
        </a>
      </template>
      <template v-slot:rightButtons>
        <a
          href="#"
          @click.prevent="openPreview()"
          class="pane-button pr-2 text-gray-500"
          :class="{ disabled: previewDisabled }"
        >
          <span v-if="preview.loading" class="spinner spinner-sm mr-2"></span>
          {{ $t("contract_form.preview.submit") }}
        </a>
        <nice-divider direction="vertical" />
        <a
          href="#"
          @click.prevent="openEmailPreview()"
          class="pane-button pr-2 text-gray-500"
          :class="{ disabled: emailPreviewDisabled }"
        >
          <span v-if="email.preview.loading" class="spinner spinner-sm mr-2"></span>
          {{ $t("contract_form.email.preview.submit") }}
        </a>
        <nice-divider direction="vertical" />
        <a
          href="#"
          data-toggle="error-popover"
          @click.prevent="submit"
          class="ml-2 pane-button"
          :class="{ disabled: submitDisabled }"
        >
          <span v-if="submitting" class="spinner spinner-sm mr-2"></span>
          {{ $t("contract_form.submit") }}
        </a>
      </template>
    </page-header>

    <div class="app-panel-body">
      <form id="letter-form" @submit.prevent="submit">
        <div>
          <form-block v-if="$db.broker.paperlessAuthenticated" :title="$t('contract_form.template_id.title')">
            <form-row direction="column">
              <db-select v-model="params.templateId" collection="paperlessTemplates" lazy size="medium" />
            </form-row>
          </form-block>

          <form-block v-else :title="$t('contract_form.description')">
            <form-row direction="column">
              <nice-button type="primary" @click="connect">
                {{ $t("contract_form.auth_button") }}
              </nice-button>
            </form-row>
          </form-block>
        </div>

        <spinner v-if="fetching" />

        <template v-if="paperlessTemplate">
          <form-block
            :title="$t('contract_form.participants.title')"
            :hint="$t('contract_form.participants.hint', { count: paperlessTemplate.participants.length })"
          >
            <div v-for="(participant, idx) in paperlessTemplate.participants" :key="idx">
              <nice-radio-group
                class="mb-2"
                v-model="userTypeSelector[participant.slotName]"
                @change="cleanParticipant(participant.slotName)"
                :options="[
                  { id: 'client', name: $t('contract_form.recipient_type_selector.contact') },
                  { id: 'user', name: $t('contract_form.recipient_type_selector.user') },
                ]"
              />
              <form-row>
                <template v-slot:label>
                  <aside class="-mt-3">
                    <span :style="{ color: participant.color }">{{ participant.slotName }}</span>
                    <footer class="text-xs text-gray-500">
                      <span>{{ $t("contract_form.role") }}: {{ participantRoles[participant.role] }}</span>
                      |
                      <span v-if="userTypeSelector[participant.slotName] === 'user'">
                        {{ $t("contract_form.variable") }}: nutzer{{
                          getRoleIndexUsers("user", participant.slotName) + 1
                        }}.xxx
                      </span>
                      <span v-else>
                        {{ $t("contract_form.variable") }}: kontakt{{
                          getRoleIndexUsers("client", participant.slotName) + 1
                        }}.xxx
                      </span>
                    </footer>
                  </aside>
                </template>
                <div v-if="participant.name && participant.email" class="-mt-1">
                  <span>{{ participant.name }}</span>
                  <footer class="text-gray-500 text-xs">{{ participant.email }}</footer>
                </div>
                <db-select
                  v-else-if="userTypeSelector[participant.slotName] === 'user'"
                  collection="activeBrokers"
                  v-model="params.participants['users'][participant.slotName]"
                />
                <client-select v-else v-model="params.participants['clients'][participant.slotName]" />
              </form-row>
            </div>
          </form-block>
          <form-block :title="$t('contract_form.links.title')" :hint="$t('contract_form.links.hint')">
            <form-row :title="$t('panes.letterForm.addProperty')">
              <property-select v-model="params.propertyId" variants />
            </form-row>

            <form-row :title="$t('panes.letterForm.addProjects')">
              <project-select v-model="params.projectId" />
            </form-row>
          </form-block>
          <form-block
            :title="$t('contract_form.tokens.title')"
            :hint="
              $t('contract_form.tokens.hint', { count: paperlessTemplate.tokens.length, resolved: resolvedTokensCount })
            "
          >
            <spinner v-if="resolvingTokens" />
            <div class="border rounded">
              <table class="w-100">
                <tr v-for="(token, idx) in paperlessTemplate.tokens" :key="idx" class="border-b last:border-b-0">
                  <td class="py-1 px-2 border-r font-mono text-xs">{{ token.name }}</td>
                  <td class="w-100 text-sm p-0">
                    <input
                      v-model="token.value"
                      class="border-none py-1 px-2 w-100 hover:bg-gray-50 focus:bg-gray-100 block"
                      :class="{
                        'bg-red-50 hover:bg-red-100 focus:bg-red-50': !token.value,
                        'text-green-700': token.value && resolvedTokens && token.value == resolvedTokens[token.name],
                      }"
                    />
                  </td>
                </tr>
              </table>
            </div>
          </form-block>
          <form-block
            :title="$t('contract_form.email.title')"
            :hint="
              $t('contract_form.email.hint', { count: paperlessTemplate.tokens.length, resolved: resolvedTokensCount })
            "
          >
            <rich-textarea
              ref="body"
              v-model="paperlessTemplate.emailBody"
              :placeholder="$t('admin.paperless.email.placeholder')"
            />
          </form-block>
        </template>
      </form>
    </div>
    <nice-dialog :model-value="isPreviewOpen" width="900px" :title="$t('contract_form.preview.title')">
      <vue-pdf-app
        v-if="pdf"
        :pdf="pdf"
        style="height: calc(100vh - 260px); min-height: 500px"
        width="100%"
      ></vue-pdf-app>
      <template #footer>
        <nice-button type="primary" :loading="submitting" @click="submit">{{ $t("contract_form.submit") }}</nice-button>
      </template>
    </nice-dialog>
    <nice-dialog
      :model-value="!!email.preview.body"
      @close="email.preview.body = null"
      append-to-body
      width="900px"
      :title="$t('contract_form.email.preview.title')"
    >
      <div v-html="email.preview.body"></div>
    </nice-dialog>
  </div>
</template>
<script>
import FormBlock from "@/components/form/Block.vue"
import orderParticipantFlow from "@/config/contract-form-participant-order"
import VuePdfApp from "vue3-pdf-app"
import "vue3-pdf-app/dist/icons/main.css"

export default {
  props: ["source"],
  components: { FormBlock, VuePdfApp },

  data() {
    return {
      submitting: false,
      params: {
        templateId: null,
        participants: {
          clients: {},
          users: {},
        },
        propertyId: (this.source?.propertyIds && this.source.propertyIds[0]) || null,
        projectId: (this.source?.projectIds && this.source.projectIds[0]) || null,
      },
      paperlessTemplate: null,
      resolvingTokens: false,
      resolvedTokens: null,
      fetching: false,
      preview: {
        loading: false,
        blob: null,
      },
      email: {
        preview: {
          loading: false,
          body: null,
        },
      },
      userTypeSelector: {},
      pdf: null,
    }
  },
  watch: {
    "params.templateId"(val) {
      this.fetchTemplate(val)
    },
    params: {
      handler() {
        this.$nextTick(_ => {
          this.resolveTokens()
        })
      },
      deep: true,
    },

    "preview.blob": {
      handler(newValue) {
        if (newValue) {
          this.registerPreviewBinds()
        } else {
          this.unbindPreviewBinds()
        }
      },
    },
  },
  methods: {
    fetchTemplate(id) {
      this.fetching = true
      this.paperlessTemplate = null
      this.$graphql(
        `{
        paperlessTemplate(id: ${id}) {
          id name participationFlow emailBody remoteNotFound
          tokens { name value calculationType }
        }
      }`
      )
        .then(({ paperlessTemplate }) => {
          if (paperlessTemplate?.remoteNotFound) {
            App.flashy(this.$t("contract_form.remote_template_error"), "danger")
          } else {
            paperlessTemplate.participants = orderParticipantFlow(paperlessTemplate.participationFlow)
            paperlessTemplate.participationFlow = undefined
            this.paperlessTemplate = paperlessTemplate
            this.params.participants["clients"] = paperlessTemplate.participants
              .map(p => p.slotName)
              .reduce((acc, k) => {
                acc[k] = null
                return acc
              }, {})
            this.userTypeSelector = paperlessTemplate.participants
              .map(p => p.slotName)
              .reduce((acc, k) => {
                acc[k] = "client"
                return acc
              }, {})
          }
        })
        .catch(this.$axios.handleError)
        .finally(_ => {
          this.fetching = false
        })
    },
    submit() {
      if (this.submitDisabled) return

      this.submitting = true
      this.$api
        .mutation("createPaperlessDocument", {
          ...this.params,
          emailBody: this.paperlessTemplate.emailBody,
          resolvedTokens: this.allResolvedTokens,
        })
        .then(_ => {
          App.flashy(this.$t("contract_form.success"))
          this.$emit("close")
        })
        .catch(this.$axios.handleError)
        .finally(_ => {
          this.submitting = false
        })
    },
    resolveTokens() {
      if (!this.paperlessTemplate || this.resolvingTokens) return

      this.resolvingTokens = true
      this.$api
        .mutation("resolvePaperlessTokens", this.params, "resolvedTokens")
        .then(({ resolvedTokens }) => {
          this.resolvedTokens = resolvedTokens
          this.paperlessTemplate.tokens.forEach(t => {
            if (resolvedTokens[t.name]) t.value = resolvedTokens[t.name]
          })
        })
        .catch(this.$axios.handleError)
        .finally(_ => {
          this.resolvingTokens = false
        })
    },
    openPreview() {
      if (this.preview.loading) return

      this.preview.loading = true
      this.$api
        .mutation(
          "previewPaperlessDocument",
          { ...this.params, resolvedTokens: this.allResolvedTokens, emailBody: this.paperlessTemplate.emailBody },
          "preview"
        )
        .then(({ preview }) => {
          this.preview.blob = preview
          this.renderPdf()
        })
        .catch(this.$axios.handleError)
        .finally(_ => {
          this.preview.loading = false
        })
    },
    openEmailPreview() {
      if (this.email.preview.loading) return

      this.email.preview.loading = true
      this.$api
        .mutation(
          "previewPaperlessEmail",
          { ...this.params, resolvedTokens: this.allResolvedTokens, emailBody: this.paperlessTemplate.emailBody },
          "body"
        )
        .then(({ body }) => {
          this.email.preview.body = body
        })
        .catch(this.$axios.handleError)
        .finally(_ => {
          this.email.preview.loading = false
        })
    },
    renderPdf() {
      this.pdf = this.base64ToArrayBuffer(this.preview.blob)
    },
    base64ToArrayBuffer(base64) {
      // Decode the base64 string to a binary string
      const binaryString = window.atob(base64)

      // Create a Uint8Array from the binary string
      const len = binaryString.length
      const bytes = new Uint8Array(len)
      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i)
      }

      // Get the ArrayBuffer from the Uint8Array
      return bytes.buffer
    },
    close() {
      this.$emit("close")
    },

    registerPreviewBinds() {
      combokeys.bind("esc", "preview", () => {
        this.preview.blob = null
      })
      combokeys.setScope("preview")
    },
    unbindPreviewBinds() {
      combokeys.deleteScope("preview")
      combokeys.unbind("esc", "preview")
      combokeys.setScope("quickview")
    },
    connect() {
      this.$api
        .mutation("connectPaperless", {}, "url")
        .then(data => {
          if (data.url) {
            window.open(data.url, "_self")
          } else {
            App.flashy(this.$t("contract_form.auth_failed"))
          }
        })
        .catch(this.$axios.handleError)
    },
    cleanParticipant(slotName) {
      if (this.params.participants["clients"].hasOwnProperty(slotName)) {
        this.params.participants["clients"][slotName] = null
      }
      if (this.params.participants["users"].hasOwnProperty(slotName)) {
        this.params.participants["users"][slotName] = null
      }
    },
    getRoleIndexUsers(category, slotName) {
      const filteredObj = Object.fromEntries(
        Object.entries(this.userTypeSelector).filter(([key, value]) => value === category)
      )
      return Object.entries(filteredObj).findIndex(object => {
        return object[0] === slotName
      })
    },
  },
  computed: {
    isPreviewOpen() {
      return !!this.preview.blob
    },
    previewDisabled() {
      return this.preview.loading || this.submitting || !this.paperlessTemplate
    },
    emailPreviewDisabled() {
      return this.email.preview.loading || this.submitting || !this.paperlessTemplate
    },
    submitDisabled() {
      return (
        this.submitting ||
        !this.paperlessTemplate ||
        this.paperlessTemplate.participants.some(
          p => !p.email && !this.params.participants[`${this.userTypeSelector[p.slotName]}s`][p.slotName]
        ) ||
        Object.values(this.allResolvedTokens).some(v => !v)
      )
    },
    allResolvedTokens() {
      if (!this.paperlessTemplate) return {}

      return this.paperlessTemplate.tokens
        .map(t => {
          return { id: t.name, value: t.value || (this.resolvedTokens && this.resolvedTokens[t.name]) }
        })
        .reduce((acc, t) => {
          acc[t.id] = t.value
          return acc
        }, {})
    },
    resolvedTokensCount() {
      if (!this.paperlessTemplate || !this.resolvedTokens) return 0

      return this.paperlessTemplate.tokens.filter(t => this.resolvedTokens[t.name]).length
    },
    participantRoles() {
      return this.$t("contract_form.roles")
    },
  },
}
</script>
