<template>
  <el-badge :value="value" :is-dot="isDot" :class="styleClass">
    <slot></slot>
  </el-badge>
</template>

<script lang="ts" setup>
import { defineProps, computed } from "vue"

type SubType = "taskStage" | "chatMessageIndicator"

const SubTypeClasses: Record<SubType, string> = {
  taskStage: "task-stage-badge",
  chatMessageIndicator: "chat-message-indicator",
}

const props = withDefaults(
  defineProps<{
    value: string | number
    isDot: boolean
    subType: SubType
  }>(),
  {
    isDot: false,
  }
)

const styleClass = computed(() => SubTypeClasses[props.subType] ?? "")
</script>

<style>
.task-stage-badge {
  width: 0;
}

.task-stage-badge > .el-badge__content {
  font-size: 10px !important;
  height: 16px !important;
  line-height: 14px !important;
  top: -6px !important;
  right: 6px !important;
  background-color: inherit;
}

.chat-message-indicator {
  position: absolute;
  top: 0;
  right: 0;
}

.chat-message-indicator > .el-badge__content {
  height: 15px !important;
  width: 15px !important;
}
</style>
