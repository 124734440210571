<template>
  <spinner v-if="loading" centered />
  <div v-else-if="message" class="app-panel">
    <div id="message-detail-container" class="app-panel-body scrollable">
      <div class="py-3 px-4">
        <div class="mb-4">
          <section class="mb-2 d-flex align-items-center justify-content-between">
            <div class="flex gap-2">
              <a
                v-if="hasCloseButton"
                href="#"
                class="fs-18 mr-3 ml-1"
                style="position: relative; top: 2px"
                @click.prevent="$emit('close')"
              >
                <fa-icon name="times" />
              </a>
              <select-popover
                :title="$t('inbox.messageDetail.addCategory')"
                :emptyOption="$t('inbox.messageDetail.emptyCategory')"
                :selected="message.messageCategory && message.messageCategory.id"
                :items="categories"
                @click="changeCategory"
              >
                <template v-slot:reference="data">
                  <nice-label :color="message.messageCategory?.color" bg-class="bg-gray-400" clickable>
                    {{ data.selectLabel }}
                    <fa-icon name="angle-down" class="ml-1" />
                  </nice-label>
                </template>
              </select-popover>
              <select-popover
                :title="$t('inbox.messageDetail.addSource')"
                :emptyOption="$t('inbox.messageDetail.emptySource')"
                :selected="message.clientSource && message.clientSource.id"
                :items="sources"
                @click="changeSource"
              >
                <template v-slot:reference="data">
                  <nice-label :color="message.clientSource ? '#ffbb00' : undefined" clickable>
                    {{ data.selectLabel }}
                    <fa-icon name="angle-down" class="ml-1" />
                  </nice-label>
                </template>
              </select-popover>
              <select-popover
                v-if="brokers.length > 1"
                :title="$t('inbox.messageDetail.shareEmail')"
                :emptyOption="$t('inbox.messageDetail.emptyEmail')"
                :selected="message.sharedBrokerIds"
                :items="brokers"
                @click="changeInvitedBrokers"
                data-tagid="shareEmail"
              >
                <template v-slot:reference="data">
                  <nice-label :color="invitedBrokers.length > 0 ? '#ff00bb' : undefined" clickable>
                    {{ data.selectLabel }}
                    <fa-icon name="angle-down" class="ml-1" />
                  </nice-label>
                </template>
              </select-popover>
              <select-popover
                v-if="groups.length > 0"
                :title="$t('inbox.messageDetail.addTag')"
                :emptyOption="$t('inbox.messageDetail.emptyTag')"
                :selected="message.groupIds"
                :items="groups"
                @click="changeGroups"
                selected-first
              >
                <template v-slot:reference="data">
                  <nice-label :color="selectedGroups.length > 0 ? '#ff00bb' : undefined" clickable>
                    {{ data.selectLabel }}
                    <fa-icon name="angle-down" class="ml-1" />
                  </nice-label>
                </template>
              </select-popover>
            </div>
            <div class="text-right">
              <small>{{ $customFilters.datetime(message.createdAt) }}</small>
            </div>
          </section>

          <header class="msg-header">
            <div class="msg-participants">
              <p>
                <span class="msg-participantLabel mr-1">{{ $t("inbox.messageDetail.from") }}</span>
                <message-participant :participant="sender" class="msg-sender" @open-client-form="openClientForm" />
              </p>
              <p>
                <span class="msg-participantLabel mr-1">{{ $t("inbox.messageDetail.to") }}</span>
                <message-participant
                  v-for="r in toRecipients"
                  :key="r.email"
                  :participant="r"
                  @open-client-form="openClientForm"
                />
              </p>
              <p v-if="message.cc.length > 0">
                <span class="msg-participantLabel mr-1">{{ $t("inbox.messageDetail.cc") }}</span>
                <message-participant
                  v-for="r in ccRecipients"
                  :key="r.email"
                  :participant="r"
                  @open-client-form="openClientForm"
                />
              </p>
              <p v-if="message.bcc.length > 0">
                <span class="msg-participantLabel mr-1">{{ $t("inbox.messageDetail.bcc") }}</span>
                <message-participant
                  v-for="r in message.bcc"
                  :key="r.email"
                  :participant="r"
                  @open-client-form="openClientForm"
                />
              </p>
              <div class="d-flex">
                <span class="msg-participantLabel mr-2">{{ $t("inbox.messageDetail.linked") }}</span>
                <p>
                  <span
                    v-if="
                      clientsNotParticipants.length == 0 &&
                      message.properties.length == 0 &&
                      message.projects.length == 0
                    "
                    class="light mr-2"
                    @click.prevent="openLinkDialog"
                  >
                    {{
                      message.clients.length > 0
                        ? $t("inbox.messageDetail.withoutProperty")
                        : $t("inbox.messageDetail.withoutBoth")
                    }}
                  </span>
                  <router-link v-for="r in clientsNotParticipants" :key="r.id" :to="r.path" class="mr-2">
                    {{ r.name }}
                  </router-link>
                  <br v-if="message.properties.length > 0 && clientsNotParticipants.length > 0" />
                  <property-card v-for="r in message.properties" :key="r.id" :property="r" class="mr-2" />
                  <br v-if="message.projects.length > 0 && clientsNotParticipants.length > 0" />
                  <router-link v-for="r in message.projects" :key="r.id" :to="r.path" class="mr-2">
                    {{ r.name }}
                  </router-link>

                  <tooltip :content="$t('inbox.messageDetail.mngLinks')">
                    <a href="#" class="light" @click.prevent="openLinkDialog">
                      <small>
                        <fa-icon name="plus" />
                      </small>
                    </a>
                  </tooltip>
                </p>
              </div>
              <p v-if="accessBrokers.length > 0 || accessDepartments.length > 0">
                <span class="msg-participantLabel mr-2">{{ $t("inbox.messageDetail.rights") }}</span>
                {{
                  accessBrokers
                    .concat(accessDepartments)
                    .map(b => b.name)
                    .join(", ")
                }}
              </p>

              <div
                class="my-1 grid grid-cols-3 auto-rows-min gap-x-2"
                style="width: 610px"
                v-if="message.previousActivity || message.nextActivities.length"
              >
                <a
                  v-if="message.previousActivity"
                  @click.prevent="quickviewLink(message.previousActivity)"
                  class="text-black rounded px-2 py-1 h-8 truncate activityBox"
                >
                  <fa-icon name="long-arrow-left" />
                  {{ message.previousActivity.name }} ({{ message.previousActivity.type == "message" ? "M" : "T"
                  }}{{ message.previousActivity.id }})
                </a>
                <a
                  v-for="item in totalActivities"
                  :key="item.id"
                  @click.prevent="quickviewLink(item)"
                  class="text-black rounded px-2 py-1 h-8 truncate activityBox"
                >
                  <fa-icon name="long-arrow-right" />
                  {{ item.name }} ({{ item.type == "message" ? "M" : "T" }}{{ item.id }})
                </a>
                <div
                  v-if="message.nextActivities.length > (message.previousActivity ? 5 : 6)"
                  class="col-span-3 text-right mr-2"
                >
                  <button @click="showAllActivities = !showAllActivities" class="text-lg">
                    <fa-icon v-if="showAllActivities" name="chevron-up" />
                    <fa-icon v-else name="chevron-down" />
                  </button>
                </div>
              </div>
              <p
                v-if="
                  message.sharedBrokerIds.length &&
                  (!$route.query.inboxBrokerId
                    ? message.brokerId != $db.broker.id
                    : message.brokerId != $route.query.inboxBrokerId)
                "
              >
                <span class="msg-participantLabel mr-1">{{ $t("inbox.messageDetail.shared") }}</span>
                <span>
                  {{ brokers.find(b => b.id == message.brokerId) && brokers.find(b => b.id == message.brokerId).name }}
                </span>
              </p>
            </div>

            <div class="d-flex align-items-center">
              <tooltip :content="$t('inbox.messageDetail.reply')">
                <a href="#" class="tableActions-action" @click.prevent="reply">
                  <fa-icon name="reply" />
                </a>
              </tooltip>
              <tooltip v-if="canReplyAll" :content="$t('inbox.messageDetail.replyAll')">
                <a href="#" class="tableActions-action" @click.prevent="replyAll">
                  <fa-icon name="reply-all" />
                </a>
              </tooltip>
              <tooltip :content="$t('inbox.messageDetail.forward')">
                <a href="#" class="tableActions-action" @click.prevent="openMessageForm('forwardMessageId')">
                  <fa-icon name="share" />
                </a>
              </tooltip>
              <dropdown>
                <dropdown-section>
                  <dropdown-item
                    v-if="message.outgoing"
                    @click="openMessageForm('resendMessageId')"
                    :title="$t('inbox.messageDetail.resend')"
                  />
                  <dropdown-item
                    @click="openMessageForm('forwardMessageId')"
                    :title="$t('inbox.messageDetail.forward')"
                  />
                  <dropdown-item
                    new-tab
                    :title="$t('inbox.messageDetail.printView')"
                    @click="printPageVisible = true"
                  />
                  <dropdown-item @click="openRightsDialog" :title="$t('inbox.messageDetail.mngRights')" />
                </dropdown-section>
                <dropdown-section>
                  <dropdown-item
                    v-if="!message.archived"
                    :title="$t('inbox.actions.archive')"
                    @click="triggerAction('archive')"
                  />
                  <dropdown-item v-else :title="$t('inbox.actions.unarchive')" @click="triggerAction('unarchive')" />
                  <dropdown-item
                    v-if="message.read"
                    :title="$t('inbox.actions.markAsUnread')"
                    @click="triggerAction('mark_as_unread')"
                  />
                  <dropdown-item
                    v-else
                    :title="$t('inbox.actions.markAsRead')"
                    @click="triggerAction('mark_as_read')"
                  />
                  <dropdown-item
                    v-if="message.spam"
                    :title="$t('inbox.actions.unmarkAsSpam')"
                    @click="triggerAction('unspam')"
                  />
                  <dropdown-item v-else :title="$t('inbox.actions.markAsSpam')" @click="triggerAction('spam')" />
                  <dropdown-item
                    v-if="message.star"
                    :title="$t('inbox.actions.unfavorite')"
                    @click="triggerAction('unstar')"
                  />
                  <dropdown-item v-else :title="$t('inbox.actions.favorite')" @click="triggerAction('star')" />
                </dropdown-section>
                <dropdown-section>
                  <dropdown-item @click="quickview('reason')" :title="$t('inbox.messageDetail.actions.reason')" />
                  <dropdown-item @click="quickview('mail')" :title="$t('inbox.messageDetail.actions.mail')" />
                  <dropdown-item @click="quickview('note')" :title="$t('inbox.messageDetail.actions.note')" />
                  <dropdown-item @click="quickview('todo')" :title="$t('inbox.messageDetail.actions.todo')" />
                  <dropdown-item @click="quickview('event')" :title="$t('inbox.messageDetail.actions.event')" />
                  <dropdown-item
                    v-if="message.clients.length > 0"
                    @click="quickview('inquiry')"
                    :title="$t('inbox.messageDetail.actions.inquiry')"
                  />
                </dropdown-section>
                <dropdown-section>
                  <dropdown-item
                    :title="message.deletedAt ? $t('inbox.actions.deleteForever') : $t('inbox.actions.delete')"
                    class="text-danger"
                    @click="triggerAction('delete')"
                    :class="{
                      disabled: !message.sendAt && message.clients.length && !$db.broker.canDeleteLinkedMessages,
                    }"
                  />
                  <dropdown-item
                    v-if="message.deletedAt"
                    :title="$t('inbox.actions.restore')"
                    @click="triggerAction('restore')"
                  />
                </dropdown-section>
                <dropdown-section>
                  <p @click.stop class="text-gray-600 text-xs px-3 py-2">
                    {{ $t("inbox.messageDetail.externalId") }} {{ externalId }}
                  </p>
                </dropdown-section>
              </dropdown>
            </div>
          </header>

          <div v-if="message.sharedBrokerIds.includes($db.broker.id)" class="mt-2">
            <a href="#" @click.prevent="unshare">
              <fa-icon name="check" class="mr-2" />
              {{ $t("inbox.messageDetail.dontShare") }}
            </a>
          </div>
        </div>

        <h3 class="msg-subject">{{ message.subject }}</h3>

        <client-form
          v-if="initialClientFormData"
          :initial-data="initialClientFormData"
          :message="message"
          @close="initialClientFormData = null"
          @create="clientCreated"
          class="my-3"
        />

        <event-card v-if="message.icsEventData" :data="message.icsEventData" :message="message" />
        <invite-event-card
          v-if="message.inviteEvent"
          :event="message.inviteEvent"
          :externalInviteEventId="message.externalInviteEventId"
        />

        <card v-if="message.fromImmoValuation && message.properties.length <= 0">
          <div class="d-flex align-items-center justify-content-between px-3 py-2 mb-4">
            <p style="font-size: 16px">
              <fa-icon name="gift" class="mr-2 text-success" style="font-size: 20px" />
              {{ $t("inbox.messageDetail.leadfisher") }}
            </p>
            <nice-button type="success" size="small" href="#" @click="runImmoValuation" :loading="runningImmoValuation">
              {{ $t("inbox.messageDetail.takeOver") }}
            </nice-button>
          </div>
        </card>

        <section v-if="message.messageAttachments.length > 0" class="mb-4">
          <div class="attachments-wrap">
            <attachment v-for="a in message.messageAttachments" :key="a.id" :attachment="a" :message="message" />
          </div>
          <div v-if="message.messageAttachments.length > 1" class="fs-12">
            <span class="ml-1 mr-2">{{ message.messageAttachments.length }} Anhänge</span>
            <a
              v-redacted.keepText
              :href="`/mailbox/messages/${messageId}/message_attachments`"
              target="_blank"
              class="link"
            >
              {{ $t("inbox.messageDetail.dlAll") }}
              <fa-icon name="arrow-to-bottom" class="ml-1" />
            </a>
          </div>
        </section>

        <section v-redacted.keepText="backdoorShowMessageBody" class="msg-body" v-html="message.body"></section>

        <div v-if="message.outgoing && message.body && message.body.match(/\/angebot\/|\/public\//i)" class="my-3">
          <nice-bar
            v-if="webExposeeExpired"
            class="mb-3"
            type="warning"
            :label="$t('inbox.messageDetail.expired', { date: $customFilters.datetime(webExposeeDeadline) })"
          />
          <div class="fluid-item align-items-center">
            <nice-switch v-model="message.lpBlocked" @change="lpBlockedChanged" />
            <label class="mb-0 fs-12">{{ $t("inbox.messageDetail.block") }}</label>
          </div>
        </div>
      </div>

      <comments-app :resource-id="messageId" resource-type="Message" />
    </div>
    <form-dialog
      :title="$t('inbox.messageDetail.editCon')"
      v-model:visible="links.dialogVisible"
      :saving="links.saving"
      @submit="changeLinks"
      append-to-body
      :autofocus="false"
    >
      <form-section>
        <form-row v-if="$db.broker.canDeleteLinkedMessages" :title="$t('inbox.messageDetail.lnkdClients')">
          <client-select multiple v-model="links.clientIds" include-children />
        </form-row>
        <form-row v-else :title="$t('inbox.messageDetail.addClients')">
          <client-select multiple v-model="links.addClientIds" include-children />
        </form-row>
        <form-row :title="$t('inbox.messageDetail.lnkdProperties')">
          <property-select multiple variants v-model="links.propertyIds" @change-project="links.projectIds = $event" />
        </form-row>
        <form-row :title="$t('inbox.messageDetail.lnkdProjects')">
          <project-select multiple v-model="links.projectIds" />
        </form-row>
      </form-section>
    </form-dialog>
    <form-dialog
      :title="$t('inbox.messageDetail.emailRights')"
      v-model:visible="rights.dialogVisible"
      :saving="rights.saving"
      @submit="changeRights"
      append-to-body
    >
      <form-section>
        <form-row :title="$t('inbox.messageDetail.accessBrokers')">
          <db-select collection="activeBrokers" multiple v-model="rights.accessBrokerIds" />
        </form-row>
        <form-row :title="$t('inbox.messageDetail.accessDepartments')">
          <db-select collection="departments" multiple v-model="rights.accessDepartmentIds" />
        </form-row>
        <p class="footnote">
          {{ $t("inbox.messageDetail.footnote1") }}
          <br />
          {{ $t("inbox.messageDetail.footnote2", { name: message.broker && message.broker.name }) }}
        </p>
      </form-section>
    </form-dialog>
    <message-print v-model:open="printPageVisible" :message="message" />
  </div>
  <div v-else class="messages-empty">
    <p class="messages-emptyTitle">{{ $t("inbox.messageDetail.emptyState") }}</p>
  </div>
</template>

<script>
import combokeys from "../../utils/shortcuts"
import MassActionButton from "./MassActionButton"
import MessageParticipant from "./MessageParticipant"
import PropertyCard from "./PropertyCard"
import Attachment from "./Attachment"
import EventCard from "./EventCard"
import InviteEventCard from "./InviteEventCard"
import ClientForm from "./ClientForm"
import CommentsApp from "@/components/Comments"
import MessagePrint from "@/views/print/Message.vue"
import eventBus from "@/config/event-bus"

export default {
  props: {
    messageId: {},
    markAsRead: {
      type: Boolean,
      default: false,
    },
    hasCloseButton: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    MessageParticipant,
    MassActionButton,
    Attachment,
    EventCard,
    InviteEventCard,
    ClientForm,
    CommentsApp,
    MessagePrint,
    PropertyCard,
  },

  data() {
    return {
      loading: false,
      message: null,
      links: {
        dialogVisible: false,
        saving: false,
        clientIds: null,
        addClientIds: [],
        propertyIds: null,
        projectIds: null,
      },
      rights: {
        accessBrokerIds: [],
        accessDepartmentIds: [],
        dialogVisible: false,
        saving: false,
      },
      initialClientFormData: null,
      printPageVisible: false,
      runningImmoValuation: false,
      showAllActivities: false,
    }
  },

  watch: {
    messageId() {
      this.printPageVisible = false
      this.message = null
      this.initialClientFormData = null
      if (this.messageId) this.fetchData()
    },
  },

  methods: {
    quickview(type) {
      const params = {
        followupMessageId: this.messageId,
        source: {
          clientIds: this.message.clients.map(({ id }) => id),
          projectIds: this.message.projects.map(({ id }) => id),
          propertyIds: this.message.properties.map(({ id }) => id),
          // body: this.message.body,
          // noteAttachments: this.message.messageAttachments.map(({ id, name, url }) => ({ id, name, url })),
        },
      }
      eventBus.$emit("quick-view", {
        type: type,
        mode: "edit",
        params,
      })
    },
    quickviewLink({ type, id }) {
      eventBus.$emit("quick-view", {
        type: type === "reminder" ? "todo" : type,
        mode: "view",
        id,
      })
    },
    async fetchData() {
      try {
        this.loading = true
        const { message } = await this.$graphql(`query message {
        message(id: ${this.messageId}, markAsRead: ${this.markAsRead}) {
          id createdAt oldCrmExternalId sendAt
          from { name email }
          to { name email }
          cc { name email }
          bcc { name email }
          subject body
          messageCategory { id name color }
          clientSource { id name }
          broker { id name }
          brokerId
          sharedBrokerIds groupIds outgoing lpBlocked
          accessBrokerIds accessDepartmentIds
          archived read star spam deletedAt
          clients { id name path emails phone warningNotice }
          properties { id name marketingType marketingTypeLabel marketingPrice categoryLabel rsTypeLabel zipCode city region country numberOfRooms livingSpace plotArea industrialArea constructionYear price valuationPrice valuationPriceFrom valuationPriceTo baseRent currency titleImageUrl path }
          projects { id name path }
          messageAttachments { id name url }
          fromImmoValuation,
          icsEventData externalInviteEventId
          inviteEvent {
            id title location startsAt endsAt allDay rsvpStatus
            participants { name email status }
          }
          previousActivity {
            id name type
          }
          nextActivities {
            id name type
          }
        }
      }`)

        this.message = message
        this.loading = false
      } catch (e) {
        this.close()
      }
    },

    triggerAction(action) {
      if (["mark_as_read", "mark_as_unread"].includes(action)) {
        this.message.read = action == "mark_as_read"
      } else if (["star", "unstar"].includes(action)) {
        this.message.star = action == "star"
      } else if (["archive", "unarchive"].includes(action)) {
        this.message.archived = action == "archive"
      } else if (["spam", "unspam"].includes(action)) {
        this.message.spam = action == "spam"
      }

      if (this.hasCloseButton) {
        this.$axios
          .post("/mailbox/messages/mass_update", {
            message_action: action,
            message_ids: [this.messageId],
          })
          .then(_ => {
            this.$emit("close")
            App.flashy(this.$t("inbox.messageDetail.editSuccess"))
            setTimeout(_ => {
              eventBus.$emit("message-deleted")
            }, 50)
          })
      } else {
        // we are in mailbox, let InboxApp handle the update
        this.$emit("trigger-action", action)
      }
    },

    close() {
      this.$emit("close")
    },

    openLinkDialog() {
      this.links.clientIds = this.message.clients.map(o => o.id)
      this.links.propertyIds = this.message.properties.map(o => o.id)
      this.links.projectIds = this.message.projects.map(o => parseInt(o.id))
      this.links.dialogVisible = true
    },

    updateMessage(attributes) {
      return this.$axios.put(`/mailbox/messages/${this.messageId}`, {
        quick_update: true,
        message: attributes,
      })
    },

    changeLinks() {
      this.links.saving = true
      this.updateMessage({
        client_ids: [...new Set(this.links.clientIds.concat(this.links.addClientIds))],
        property_ids: this.links.propertyIds,
        project_ids: this.links.projectIds,
      })
        .then(async () => {
          const { message } = await this.$graphql(`{
            message(id: ${this.messageId}) {
              clients {
                id, name, path, emails
              },
              properties {
                id, name, path
              },
              projects {
                id, name, path
              }
            }
          }`)

          this.message.clients = message.clients
          this.message.properties = message.properties
          this.message.projects = message.projects

          this.links.clientIds = []
          this.links.addClientIds = []
          this.links.propertyIds = []
          this.links.projectIds = []
          this.links.dialogVisible = false
        })
        .catch(this.$axios.handleError)
        .finally(_ => {
          this.links.saving = false
        })
    },

    openRightsDialog() {
      this.rights.accessBrokerIds = this.message.accessBrokerIds
      this.rights.accessDepartmentIds = this.message.accessDepartmentIds
      this.rights.dialogVisible = true
    },

    changeRights() {
      this.rights.saving = true
      this.updateMessage({
        access_broker_ids: this.rights.accessBrokerIds,
        access_department_ids: this.rights.accessDepartmentIds,
      })
        .then(async () => {
          this.message.accessBrokerIds = this.rights.accessBrokerIds
          this.message.accessDepartmentIds = this.rights.accessDepartmentIds
          this.rights.accessBrokerIds = []
          this.rights.accessDepartmentIds = []
          this.rights.dialogVisible = false
        })
        .finally(_ => {
          this.rights.saving = false
        })
    },

    changeCategory(category) {
      this.message.messageCategory = category
      this.updateMessage({ message_category_id: category.id || null })
    },

    changeSource(source = {}) {
      this.message.clientSource = source
      this.updateMessage({ client_source_id: source.id || null })
    },

    changeInvitedBrokers(broker) {
      if (!broker.id) {
        this.message.sharedBrokerIds = []
      } else if (this.message.sharedBrokerIds.includes(broker.id)) {
        this.message.sharedBrokerIds = this.message.sharedBrokerIds.filter(id => id != broker.id)
      } else {
        this.message.sharedBrokerIds.push(broker.id)
      }

      this.updateMessage({ broker_ids: this.message.sharedBrokerIds })
    },

    changeGroups(group) {
      if (!group.id) {
        this.message.groupIds = []
      } else if (this.message.groupIds.includes(group.id)) {
        this.message.groupIds = this.message.groupIds.filter(id => id != group.id)
      } else {
        this.message.groupIds.push(group.id)
      }

      this.updateMessage({ group_ids: this.message.groupIds })
    },

    reply() {
      eventBus.$emit("quick-view", {
        type: "message",
        mode: "edit",
        params: {
          source: {
            replyMessageId: this.messageId,
          },
        },
      })
    },
    replyAll() {
      eventBus.$emit("quick-view", {
        type: "message",
        mode: "edit",
        params: {
          source: {
            replyAllMessageId: this.messageId,
          },
        },
      })
    },
    openMessageForm(key) {
      eventBus.$emit("quick-view", {
        type: "message",
        mode: "edit",
        params: {
          source: {
            [key]: this.messageId,
          },
        },
      })
    },

    unshare() {
      this.message.sharedBrokerIds = this.message.sharedBrokerIds.filter(id => id != this.$db.broker.id)
      this.updateMessage({ broker_ids: this.message.sharedBrokerIds })
    },

    lpBlockedChanged() {
      this.updateMessage({ lp_blocked: this.message.lpBlocked })
    },

    async clientCreated() {
      this.initialClientFormData = null

      const { message } = await this.$graphql(`{
        message(id: ${this.messageId}) {
          clients {
            id, name, path, emails
          },
        }
      }`)

      this.message.clients = message.clients
    },

    openClientForm(data) {
      this.initialClientFormData = data
    },

    runImmoValuation() {
      if (this.runningImmoValuation) return

      this.runningImmoValuation = true
      this.$axios
        .post(`/services/messages/${this.messageId}/run_immo_valuation`)
        .then(({ data }) => {
          if (data.property_id) {
            this.$router.push(`/portfolio/properties/${data.property_id}`)
          } else if (data.client_id) {
            this.$router.push(`/contacts/clients/${data.client_id}`)
          }
          App.flashy(this.$t("inbox.messageDetail.immoValuationSuccess"))
        })
        .catch(this.$axios.handleError)
        .finally(() => (this.runningImmoValuation = false))
    },
  },

  computed: {
    backdoorShowMessageBody() {
      if (!this.message?.subject) return false
      return /delivery\sfailed/i.test(this.message.subject)
    },
    sender() {
      if (this.message) {
        const senderEmail = (this.message.from.email || "").toLowerCase()
        return {
          ...this.message.from,
          client: this.message.clients.find(c => c.emails.includes(senderEmail)),
          sender: true,
        }
      }
    },
    toRecipients() {
      return this.message.to.map(o => ({
        ...o,
        client: this.message.clients.find(c => c.emails.includes(o.email.toLowerCase())),
      }))
    },
    ccRecipients() {
      return this.message.cc.map(o => ({
        ...o,
        client: this.message.clients.find(c => c.emails.includes(o.email.toLowerCase())),
      }))
    },
    participants() {
      return [this.sender].concat(this.toRecipients).concat(this.ccRecipients)
    },
    clientsNotParticipants() {
      const clientParticipants = this.participants.map(p => p.client).filter(o => o)

      return this.message.clients.filter(c => !clientParticipants.map(cp => cp.id).includes(c.id))
    },
    canReplyAll() {
      return this.message.to.length > 1 || this.message.cc.length > 0
    },
    categories() {
      return this.$db.get("messageCategories")
    },
    sources() {
      return this.$db.get("clientSources")
    },
    brokers() {
      return this.$db
        .get("brokers")
        .filter(b => b.active)
        .map(b => ({ id: b.id, name: b.internalName }))
    },
    departments() {
      return this.$db.get("departments")
    },
    groups() {
      return this.$db.shopData.activityGroups
        .map(g => {
          const superGroup = this.$db.shopData.superGroups.find(sg => sg.id == g.superGroupId)
          return {
            id: g.id,
            name: superGroup ? `${superGroup.name}: ${g.name}` : g.name,
          }
        })
        .sort((a, b) => (a.name > b.name ? 1 : -1))
    },
    invitedBrokers() {
      return this.message.sharedBrokerIds.map(id => this.brokers.find(b => b.id == id)).filter(b => b)
    },
    selectedGroups() {
      return this.message.groupIds.map(id => this.groups.find(b => b.id == id)).filter(b => b)
    },
    accessBrokers() {
      if (!this.message.accessBrokerIds) return []
      return this.message.accessBrokerIds.map(id => this.brokers.find(b => b.id == id)).filter(o => o)
    },
    accessDepartments() {
      if (!this.message.accessDepartmentIds) return []
      return this.message.accessDepartmentIds.map(id => this.departments.find(b => b.id == id)).filter(o => o)
    },
    webExposeeDeadline() {
      const liveDuration = this.$db.shopData.webExposeeLiveDuration
      if (!liveDuration) return null
      return moment().subtract(liveDuration, "days")
    },
    webExposeeExpired() {
      if (!this.webExposeeDeadline) return false
      if (!this.message.body || !this.message.body.match(/\/angebot\//i)) return false
      return moment(this.message.createdAt).isBefore(this.webExposeeDeadline)
    },
    externalId() {
      return this.message.oldCrmExternalId || `M${this.message.id}`
    },
    totalActivities() {
      let shownActivities = 6
      if (this.message.previousActivity) shownActivities = shownActivities - 1
      return this.showAllActivities
        ? this.message.nextActivities
        : this.message.nextActivities.slice(0, shownActivities)
    },
  },

  mounted() {
    if (this.messageId) this.fetchData()

    window.messageDetailEvents = {
      clientCreated: this.clientCreated.bind(this),
    }

    // combokeys.bind("r", e => {
    //   if (!this.messageId) return null
    //   e.preventDefault()
    //   this.reply()
    // })
    // combokeys.bind("command+shift+r", e => {
    //   if (!this.messageId) return null
    //   e.preventDefault()
    //   this.canReplyAll ? this.replyAll() : this.reply()
    // })
    // combokeys.bind("command+shift+f", e => {
    //   if (!this.messageId) return null
    //   e.preventDefault()
    //   this.forward()
    // })
  },
}
</script>

<style lang="css" scoped>
.app-panel {
  background: white;
}

.messages-empty {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.messages-emptyTitle {
  color: #555555;
  font-size: 1.25rem;
}

.msg-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.msg-participantLabel {
  color: #555555;
  min-width: 60px;
  display: inline-block;
}
.msg-subject {
  font-size: 1.75rem;
  margin-bottom: 2rem;
  font-weight: 500;
  line-height: 1.25;
}

.msg-client-form {
  display: none;
  border: 1px solid #ddd;
  background: rgba(0, 0, 0, 0.01);
  border-radius: 4px;
  margin: 2rem 0;
}

.attachments-wrap {
  display: flex;
  flex-wrap: wrap;
}

.activityBox {
  background-color: rgb(230, 230, 230);
  width: 190px;
  margin-bottom: 12px;
}

.activityBox:hover {
  background-color: rgb(205, 205, 205);
}
</style>
