<template>
  <form-dialog
    :autofocus="false"
    @submit="submitOutOfOffice"
    :visible="modalVisible"
    @update:visible="$emit('update:modalVisible', $event)"
    :title="$t('header.outOfOfficeForm.title')"
    width="800px"
    :disabled="updating"
  >
    <form-section v-if="resource">
      <form-row :title="$t('header.outOfOfficeForm.timeframe')">
        <div class="flex items-center">
          <nice-date-picker
            class="w-100"
            fixed-position
            v-model="resource.outOfOfficeStartsAt"
            type="datetime"
            :placeholder="$t('header.outOfOfficeForm.start')"
            format="dd.MM.yyyy HH:mm"
            default-time="00:00:00"
          />
          <span class="mx-2 text-sm text-gray-500">-</span>
          <nice-date-picker
            class="w-100"
            fixed-position
            v-model="resource.outOfOfficeEndsAt"
            type="datetime"
            :placeholder="$t('header.outOfOfficeForm.end')"
            format="dd.MM.yyyy HH:mm"
            default-time="23:59:00"
          />
        </div>
      </form-row>
      <form-row :title="$t('header.outOfOfficeForm.re')" :hint="$t('header.outOfOfficeForm.reHint')">
        <nice-input size="small" v-model="resource.outOfOfficeSubject" />
      </form-row>
      <form-row
        v-if="!$db.shopData.outOfOfficeEmailForwarding"
        :title="$t('header.outOfOfficeForm.shareWithOthers')"
        :hint="$t('header.outOfOfficeForm.shareWithOthersHint')"
      >
        <db-select v-model="resource.outOfOfficeShareWithBrokerIds" collection="activeBrokers" multiple />
      </form-row>
      <form-row :title="$t('header.outOfOfficeForm.snippets')" :hint="$t('header.outOfOfficeForm.snippetsHint')">
        <snippet-select @change="snippetSelected" alt-design />
      </form-row>
      <form-row
        :title="$t('header.outOfOfficeForm.message')"
        direction="column"
        :hint="$t('header.outOfOfficeForm.messageHint')"
      >
        <rich-textarea ref="body" v-model="resource.outOfOfficeBody" />
      </form-row>
    </form-section>
  </form-dialog>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import SnippetSelect from "@/components/message/SnippetSelect.vue"

export default defineComponent({
  components: { SnippetSelect },
  props: {
    modalVisible: Boolean,
  },
  data() {
    return {
      resource: null as any,
      updating: false,
    }
  },
  watch: {
    modalVisible(newValue) {
      if (newValue) {
        this.resource = null
        this.fetchData()
      }
    },
  },
  methods: {
    snippetSelected($event) {
      const val = $($event.target).val()
      this.$axios.get(`/api/v1/snippets/${val}`).then(({ data: snippet }) => {
        if (snippet.message_template_id) {
          ;(this.$refs.body as any).$options.froalaEditor.html.set(snippet.body)
        } else {
          try {
            ;(this.$refs.body as any).$options.froalaEditor.html.insert(snippet.body)
          } catch (error) {
            ;(this.$refs.body as any).$options.froalaEditor.html.set(snippet.body)
          }
        }

        $($event.target).val("")
        this.resource.outOfOfficeBody = (this.$refs.body as any).$options.froalaEditor.html.get()
      })
    },
    async fetchData() {
      const { broker } = await this.$graphql(`{
        broker {
          outOfOfficeStartsAt outOfOfficeEndsAt outOfOfficeSubject outOfOfficeBody outOfOfficeShareWithBrokerIds
        }
      }`)

      this.resource = broker
    },
    submitOutOfOffice() {
      this.updating = true
      this.$api
        .updateBroker(this.resource)
        .then(_ => {
          this.$emit("update:modalVisible", false)
          App.flashy("Änderungen wurden erfolgreich gespeichert!")
        })
        .catch(this.$axios.handleError)
        .finally(() => (this.updating = false))
    },
  },
})
</script>
