<template>
  <div class="app-panel">
    <page-header>
      <template v-slot:leftMenu>
        <a href="#" @click.prevent="close" class="pane-button">
          <fa-icon name="times" />
        </a>
      </template>
      <template v-slot:rightButtons>
        <div>
          <ps-button @click="prev" :disabled="currentItemIndex === 0">
            <fa-icon name="arrow-left" />
          </ps-button>
          {{ currentItemIndex + 1 + ` ${$t("deals.inquiryManager.from")} ` + items.length }}
          <ps-button @click="next" :disabled="currentItemIndex + 1 === items.length">
            <fa-icon name="arrow-right" />
          </ps-button>
        </div>
        <a
          href="#"
          data-toggle="error-popover"
          @click.prevent="submit"
          :disabled="(!isDirty && !submitting) || undefined"
          class="ml-2 pane-button"
          data-tagid="messageSubmit"
        >
          <span v-if="submitting" class="spinner spinner-sm mr-2"></span>
          {{ $t("deals.inquiryManager.submit") }}
        </a>
      </template>
    </page-header>
    <spinner v-if="loading" />
    <div v-else-if="currentClient" class="app-panel-body h-100">
      <section class="flex h-3/4 overflow-auto">
        <div v-if="currentClient.photoUrl" class="w-1/3">
          <div
            class="bg-cover rounded-full"
            :style="`width: 135px; height: 135px; background-image: url(&quot;${currentClient.photoUrl}&quot;);`"
          ></div>
        </div>
        <div :class="[{ 'w-2/3': currentClient.photoUrl }, 'w-100']">
          <form-row
            v-if="currentClient.salutation"
            :title="$t('deals.inquiryManager.salutation')"
            class="mb-1 items-baseline"
          >
            <p>{{ formSalutation }}</p>
          </form-row>
          <form-row v-if="currentClient.name" :title="$t('deals.inquiryManager.name')" class="mb-1 items-baseline">
            <router-link :to="'/contacts/clients/' + currentItem.client_id" class="link">
              {{ currentClient.name }}
            </router-link>
          </form-row>
          <form-row
            v-if="currentClient.company"
            :title="$t('deals.inquiryManager.company')"
            class="mb-1 items-baseline"
          >
            <p>{{ currentClient.company }}</p>
          </form-row>
          <form-row
            v-if="currentClient.homeStreet"
            :title="$t('deals.inquiryManager.street')"
            class="mb-1 items-baseline"
          >
            <p>{{ currentClient.homeStreet }} {{ currentClient.homeHouseNumber }}</p>
          </form-row>
          <form-row
            v-if="currentClient.homeZipCode"
            :title="$t('deals.inquiryManager.zipcode')"
            class="mb-1 items-baseline"
          >
            <p>{{ currentClient.homeZipCode }}</p>
          </form-row>
          <form-row v-if="currentClient.homeCity" :title="$t('deals.inquiryManager.city')" class="mb-1 items-baseline">
            <p>{{ currentClient.homeCity }}</p>
          </form-row>
          <form-row
            v-if="currentClient.homePhone"
            :title="$t('deals.inquiryManager.phone')"
            class="mb-1 items-baseline"
          >
            <p>{{ currentClient.homePhone }}</p>
          </form-row>
          <form-row v-if="currentClient.phone" :title="$t('deals.inquiryManager.mobile')" class="mb-1 items-baseline">
            <p>{{ currentClient.phone }}</p>
          </form-row>
          <form-row v-if="currentClient.homeFax" :title="$t('deals.inquiryManager.fax')" class="mb-1 items-baseline">
            <p>{{ currentClient.homeFax }}</p>
          </form-row>
          <form-row v-if="currentClient.email" :title="$t('deals.inquiryManager.email')" class="mb-1 items-baseline">
            <a class="link" @click.prevent="quickviewMail">{{ currentClient.email }}</a>
          </form-row>
          <form-row v-if="false" :title="$t('deals.inquiryManager.preferred')" class="mb-1 items-baseline"></form-row>
          <form-row v-if="false" :title="$t('deals.inquiryManager.wish')" class="mb-1 items-baseline"></form-row>
          <form-row
            v-if="currentItem.property_name"
            :title="$t('deals.inquiryManager.propertyName')"
            class="mb-1 items-baseline"
          >
            <router-link :to="'/portfolio/properties/' + currentItem.property_id" class="link">
              {{ currentItem.property_name }}
            </router-link>
          </form-row>
          <p v-if="!!taskBody" v-html="taskBody" class="pt-4"></p>
        </div>
      </section>
      <section class="h-1/4">
        <form-row :title="$t('deals.inquiryManager.feeling')">
          <nice-slider v-model="editableItem.feeling" :max="100" :step="1" class="w-100" />
        </form-row>
        <form-row :title="$t('deals.inquiryManager.note')">
          <nice-textarea v-model="editableItem.note"></nice-textarea>
        </form-row>
        <form-row :title="$t('deals.inquiryManager.source')">
          <dropdown :record="editableItem" :field="sourceFieldData" />
        </form-row>
      </section>
    </div>
  </div>
</template>

<script>
import { formatPayload } from "@/config/detail-view-form"
import Dropdown from "@/components/detail-view/Dropdown.vue"
import eventBus from "@/config/event-bus"
import shortcuts from "@/utils/shortcuts"
import { toRaw } from "vue"

export default {
  components: { Dropdown },
  props: ["type", "data"],
  data() {
    return {
      loading: false,
      submitting: false,
      currentItemIndex: 0,
      currentClient: undefined,
      taskBody: undefined,
      editableItem: {
        feeling: undefined,
        note: undefined,
        client_source_id: undefined,
      },
    }
  },
  methods: {
    prev() {
      if (this.currentItemIndex === 0) return
      this.currentItemIndex--
      this.fetchClient()
      this.fetchDeal()
    },
    next() {
      if (this.currentItemIndex + 1 === this.items.length) return
      this.currentItemIndex++
      this.fetchClient()
      this.fetchDeal()
    },
    close() {
      this.$emit("close")
    },
    getEditable() {
      const { feeling, note, client_source_id } = this.currentItem
      this.editableItem = { feeling, note, client_source_id }
    },
    submit() {
      this.submitting = true

      const payload = { ...this.currentItem, ...this.editableItem }

      this.$axios
        .put(`/api/v1/client_properties/${this.currentItem.id}`, {
          client_property: formatPayload(payload, []),
          updated_at: this.currentItem["updated_at"],
        })
        .then(() => {
          App.flashy(this.$t("admin.components.shopForm.successfullySavedChanges"))
          this.submitting = false
          Object.assign(this.currentItem, payload)
        })
        .catch(this.$axios.handleError)
    },
    fetchClient() {
      this.$graphql(
        `{ client(id: "${this.currentItem.client_id}") {
        name email company salutation phone photoUrl
        homePhone homeStreet homeZipCode homeHouseNumber homeFax homeCountry homeCity
       } }`
      )
        .then(({ client }) => {
          this.currentClient = client
          this.getEditable()
        })
        .catch(error => {
          console.debug(error)
        })
    },
    async fetchDeal() {
      const { deal } = await this.$graphql(`query deal {
        deal(id: ${this.currentItem.id}) {
          initialActivity {
            type: __typename
            ... on Message {
              id body
            }
            ... on Task {
              id body
            }
            ... on Policy {
              id
            }
          }
        }
      }`)
      this.taskBody = deal.initialActivity?.body
    },
    quickviewMail() {
      eventBus.$emit("quick-view", {
        type: "mail",
        mode: "edit",
        params: {
          source: {
            clientIds: [this.currentItem.client_id],
          },
        },
      })
    },
    registerKeyBinds() {
      shortcuts.bind("left", "inquirymanager", this.prev, 50)
      shortcuts.bind("right", "inquirymanager", this.next, 50)
      shortcuts.setScope("inquirymanager")
    },
    unregisterKeyBinds() {
      shortcuts.unbind("left, right", "inquirymanager")
    },
  },
  beforeUnmount() {
    this.unregisterKeyBinds()
  },
  computed: {
    items() {
      return toRaw(this.data)
    },
    isDirty() {
      const { feeling, note, client_source_id } = this.items.find(d => d.id == this.currentItem.id)
      return !_.isEqual(this.editableItem, { feeling, note, client_source_id })
    },
    currentItem() {
      return this.items[this.currentItemIndex]
    },
    formSalutation() {
      const customSalutation = this.$db.shopData.salutations.find(s => s.internalName === this.currentClient.salutation)
      if (customSalutation) return customSalutation.name
      return this.$t(
        `detailView.nameSalutation${
          this.currentClient.salutation.charAt(0).toUpperCase() + this.currentClient.salutation.slice(1)
        }`
      )
    },
    sourceFieldData() {
      return {
        fieldName: "client_source_id",
        formField: {
          additionalParams: undefined,
          dbOptions: "clientSources",
          group: "general",
          label: "Quelle",
          type: "dropdown",
        },
      }
    },
  },
  mounted() {
    if (!!this.currentItem) {
      this.fetchClient()
      this.fetchDeal()
    }
    this.registerKeyBinds()
  },
}
</script>

<style scoped>
.action-btn {
  padding: 0.25rem;
  width: 28px;
  height: 28px;
  border-radius: 5px;
}
.action-btn:hover {
  background-color: rgba(240, 240, 240, 0.587);
}
</style>
