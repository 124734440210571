<template>
  <nice-select
    ref="select"
    :multiple="multiple"
    filterable
    clearable
    :modelValue="modelValue"
    @update:modelValue="handleUpdate"
    default-first-option
    :placeholder="$t('propertySelect.placeholder')"
    :reserve-keyword="reserveKeyword"
    remote
    :remote-method="fetchProperties"
    :options="properties"
    :loading="loading"
    :size="size"
    :label-fn="labelFn"
  >
    <template #tags="{ $onRemoveValue, values }">
      <span v-for="id in values" :key="id">
        <el-tag>
          <span class="text-black">{{ cachedPropertiesMap.get(id)?.name }}</span>
          <i
            class="fal fa-times clear-tag text-slate-500 hover:text-slate-800 ml-2 cursor-pointer"
            @click.prevent="$onRemoveValue(id)"
          />
        </el-tag>
      </span>
    </template>
    <template #option="{ item }">
      <property-select-option class="property-select" :property="item" />
    </template>
  </nice-select>
</template>

<script>
import PropertySelectOption from "./PropertySelectOption"
import { cacheProperties, cachedPropertiesMap } from "@/config/remote-data-cache"

export default {
  props: {
    modelValue: {
      type: String,
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    reserveKeyword: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "default",
    },
    variants: {
      type: Boolean,
      default: false,
    },
  },
  expose: ["prefill"],
  emits: ["update:modelValue", "change-project", "ready"],
  components: {
    PropertySelectOption,
  },
  setup() {
    return { cachedPropertiesMap }
  },
  data() {
    return {
      properties: [],
      loading: false,
      selectedProperties: [],
      selected: [],
    }
  },
  watch: {
    selected: {
      handler(newValue) {
        if (!newValue || !this.multiple) return
        const selectedPropertyMap = this.selectedProperties.reduce((agg, cur) => ({ ...agg, [cur.id]: cur }), {})
        const propertyMap = this.properties.reduce((agg, cur) => ({ ...agg, [cur.id]: cur }), {})
        this.selectedProperties = this.selected?.map(id => selectedPropertyMap[id] || propertyMap[id]) || []
      },
      deep: true,
    },
    selectedProperties: {
      handler(newValue) {
        if (!newValue) return
        setTimeout(() => {
          const projectIds = this.selectedProperties.length
            ? [...new Set(this.selectedProperties.map(s => (s ? s.project_id : undefined)).filter(Boolean))]
            : undefined
          this.$emit("change-project", projectIds)
        }, 100)
      },
      deep: true,
    },
  },
  methods: {
    labelFn(val) {
      return this.selectedProperties.find(({ id }) => id === val)?.name
    },
    async fetchProperties(query) {
      if (!query) {
        this.properties = []
        return
      }

      this.loading = true
      const { data } = await this.$axios.get(`/portfolio/properties?q=${query}&include_variants=${this.variants}`)
      this.properties = data.data
      cacheProperties(data.data)
      this.loading = false
    },
    handleUpdate(value) {
      this.$emit("update:modelValue", value)
      this.selected = value
      this.$refs.select?.select?.blur()
    },
    prefillSetProperties() {
      const propertyMap = this.properties.reduce((agg, cur) => ({ ...agg, [cur.id]: cur }), {})
      this.selectedProperties = this.multiple
        ? this.modelValue.map(id => propertyMap[id])
        : propertyMap[this.modelValue]
    },
    prefill() {
      if (!this.modelValue) return
      const ids = this.multiple ? this.modelValue.join(",") : this.modelValue
      if (!ids) return

      this.$axios.get(`/portfolio/properties?property_ids=${ids}`).then(({ data }) => {
        this.properties = data.data
        this.prefillSetProperties()
        cacheProperties(data.data)
        this.$emit("ready")
      })
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.prefill()
    })
  },
}
</script>

<style scoped>
.property-select {
  height: initial;
  line-height: initial;
  padding-top: 8px;
  padding-bottom: 8px;
}
</style>
