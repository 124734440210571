<template>
  <div class="comment-wrap" :class="{ 'comment-form--expanded': commentFormExpanded }">
    <div class="flex justify-end mb-3 -mt-1">
      <a href="#" @click="changeOrder" class="text-gray-600 text-sm">
        <fa-icon name="sort-alt" />
        {{
          $t("commentsApp.sort", {
            action: commentsOrder == "asc" ? $t("commentsApp.newest") : $t("commentsApp.oldest"),
          })
        }}
      </a>
      <a href="#" @click="showActivityLogs" class="ml-3 text-gray-600 text-sm">
        {{ $t("commentsApp.logs", { action: hideActivityLogs ? $t("commentsApp.show") : $t("commentsApp.hide") }) }}
      </a>
    </div>
    <div v-if="!loading" class="comment-list" @click="commentFormExpanded = false">
      <slot v-if="commentsOrder == 'asc' && activityLogsVisible" />
      <div v-for="item in items" :key="(item.isLog ? 'log-' : 'comment-') + item.id">
        <div v-if="item.isLog" class="activityLog-item">
          <p>
            <strong>{{ (item.broker && item.broker.name) || $t("commentsApp.sync") }}</strong>
            {{ item.prettyText }}
          </p>
          <tooltip class="activityLog-date" :content="$customFilters.datetime(item.created_at)" placement="left">
            <span>{{ $customFilters.calendar(item.created_at) }}</span>
          </tooltip>
        </div>
        <comment-item v-else :item="item" @delete="removeItem" />
      </div>
      <slot v-if="commentsOrder == 'desc' && activityLogsVisible" />
    </div>

    <footer class="comment-footer" @click.stop="commentFormExpanded = true">
      <div>
        <div
          v-if="resourceType == 'Message' && lastComment"
          v-show="lastCommentVisible"
          class="lastComment-wrapper"
          @click.stop="scrollDown"
        >
          <comment-item :item="lastComment" style="pointer-events: none" />
        </div>
        <div class="comment-item comment-form">
          <div class="comment-itemAvatar">
            <avatar :broker="$db.broker" size="32px" />
          </div>
          <div class="comment-itemContent">
            <nice-input
              type="textarea"
              :autosize="{ minRows: 1, maxRows: 4 }"
              :placeholder="$t('commentsApp.placeholder')"
              @keydown.enter="handleEnter"
              :class="{ disabled: posting }"
              v-model="body"
            />
          </div>
        </div>
      </div>
      <div class="flex justify-between">
        <div class="flex items-center">
          <div style="padding: 0 24px"><!-- For styling --></div>
          <tooltip :content="$t('commentsApp.collaborators.info')" class="mr-2">
            <span class="text-sm text-gray-600 cursor-default">{{ $t("commentsApp.collaborators.title") }}</span>
          </tooltip>
          <broker-avatars :brokerIds="collaborators" />
          <tooltip :content="$t('commentsApp.collaborators.add')">
            <button class="py-1 px-2 hover:bg-gray-100 rounded-md" @click="modalVisible = true">
              <fa-icon name="plus" class="text-sm" />
            </button>
          </tooltip>
        </div>
        <div>
          <tooltip
            v-if="collaborators.find(b => b == $db.broker.id)"
            :content="$t('commentsApp.collaborators.leaveTooltip')"
          >
            <button
              @click="updateWatchers(collaborators.filter(b => b != $db.broker.id))"
              class="p-2 text-sm text-gray-600 hover:bg-gray-100 rounded-md"
            >
              <fa-icon name="bell-slash" />
              {{ $t("commentsApp.collaborators.leave") }}
            </button>
          </tooltip>
          <tooltip v-else :content="$t('commentsApp.collaborators.joinTooltip')">
            <button
              @click="updateWatchers(collaborators.concat($db.broker.id))"
              class="p-2 text-sm text-gray-600 hover:bg-gray-100 rounded-md"
            >
              <fa-icon name="bell" />
              {{ $t("commentsApp.collaborators.join") }}
            </button>
          </tooltip>
        </div>
      </div>
    </footer>
    <form-dialog
      width="360px"
      footer-type="bigButton"
      :visible="modalVisible"
      @close="modalVisible = false"
      :title="$t('commentsApp.collaborators.add')"
      append-to-body
      @submit="updateWatchers(totalCollaborators, true), (modalVisible = false)"
    >
      <db-select fixed-position v-model="totalCollaborators" multiple collection="activeBrokers" class="-my-2" />
    </form-dialog>
  </div>
</template>

<script>
import CommentItem from "./CommentItem"
import { formatLog } from "@/config/logs"

export default {
  props: ["resourceType", "resourceId", "watchers"],

  components: {
    CommentItem,
  },

  data() {
    return {
      comments: [],
      collaborators: this.watchers || [],
      totalCollaborators: this.watchers || [],
      loading: true,
      posting: false,
      modalVisible: false,
      body: "",
      commentFormExpanded: false,
      lastCommentVisible: true,
      logs: {
        data: [],
        loading: false,
      },
      hideActivityLogs: this.$db.broker.hideActivityLogs || false,
      commentsOrder: this.$db.broker.commentsOrder || "asc",
    }
  },

  methods: {
    fetchData() {
      this.loading = true

      this.$axios
        .get(`/api/v1/comments?commentable_id=${this.resourceId}&commentable_type=${this.resourceType}`)
        .then(({ data }) => {
          this.comments = data.data
          return this.fetchLogs(this.resourceId, this.resourceType)
        })
        .then(res => {
          this.loading = false
          this.$nextTick(_ => {
            $("#message-detail-container").trigger("scroll")
          })
        })
    },

    handleEnter(e) {
      if (this.posting) {
        return
      }
      if (!e.shiftKey) {
        e.preventDefault()
        this.addItem()
      }
    },

    addItem() {
      if (!this.body || this.posting) return

      this.posting = true

      this.$axios
        .post("/api/v1/comments", {
          comment: {
            body: this.body,
            commentable_id: this.resourceId,
            commentable_type: this.resourceType,
          },
        })
        .then(() => {
          this.body = ""
          this.posting = false
          if (this.commentsOrder == "asc") $(".comment-wrap").closest(".app-panel-body").get(0).scrollTop = 9999999
        })
        .catch(err => {
          this.posting = false
          this.$axios.handleError(err)
        })
    },

    removeItem(comment) {
      this.comments = this.comments.filter(o => o.id !== comment.id)
    },

    subscribe() {
      this.$pusher.off(`comment:${this.resourceType}:${this.resourceId}:created`)
      this.$pusher.off(`comment:${this.resourceType}:${this.resourceId}:destroyed`)

      this.$pusher.on(`comment:${this.resourceType}:${this.resourceId}:created`, comment => {
        this.comments = this.comments.filter(o => o.id !== comment.id).concat([comment])
      })

      this.$pusher.on(`comment:${this.resourceType}:${this.resourceId}:destroyed`, data => {
        this.comments = this.comments.filter(o => o.id !== data.comment_id)
      })
    },

    fetchLogs() {
      this.logs.loading = true
      return this.$axios
        .get(`/api/v1/activity_logs?loggable_id=${this.resourceId}&loggable_type=${this.resourceType}`)
        .then(({ data }) => {
          this.logs.data = data.data.map(b => formatLog(b, this.brokers))
          this.logs.loading = false
        })
    },

    scrollDown() {
      const $container = document.getElementById("message-detail-container")

      if (this.$lastComment && $container) $container.scrollTo({ top: this.$lastComment.offsetTop })
    },
    showActivityLogs() {
      this.hideActivityLogs = !this.hideActivityLogs
      this.$db.updateBroker({ hideActivityLogs: this.hideActivityLogs })
    },
    changeOrder() {
      this.commentsOrder == "asc" ? (this.commentsOrder = "desc") : (this.commentsOrder = "asc")
      this.$db.updateBroker({ commentsOrder: this.commentsOrder })
    },
    updateWatchers(watchers, all) {
      this.$api
        .mutation("updateTask", {
          id: this.resourceId,
          payload: {
            watcherBrokerIds: watchers,
          },
        })
        .then(() => {
          this.totalCollaborators = watchers
          this.collaborators = watchers
        })
      if (all) this.collaborators = this.totalCollaborators
    },
  },

  computed: {
    hydratedItems() {
      let items = this.comments.map(c => ({ ...c, isComment: true }))
      if (this.activityLogsVisible) items = items.concat(this.logs.data)

      return items.map(o => ({ ...o, broker: this.brokers.find(b => b.id == o.broker_id) }))
    },
    items() {
      return _.orderBy(this.hydratedItems, "created_at", this.commentsOrder)
    },
    lastComment() {
      return this.comments[this.comments.length - 1]
    },
    $lastComment() {
      if (this.lastComment) {
        return $(".comment-list .comment-item").last().get(0)
      }
    },
    brokers() {
      return this.$db.shopData.brokers
    },
    activityLogsVisible() {
      return !this.hideActivityLogs
    },
  },

  mounted() {
    this.fetchData()
    this.subscribe()

    $(document).on("click", () => {
      this.commentFormExpanded = false
      return true
    })

    const commentBoxHeight = 60
    $("#message-detail-container").on(
      "scroll",
      _.throttle(e => {
        if (!this.$lastComment) return

        const threshold =
          this.$lastComment.offsetTop - e.target.offsetHeight + this.$lastComment.offsetHeight + commentBoxHeight

        this.lastCommentVisible = e.target.scrollTop < threshold
      }, 16)
    )
  },
}
</script>

<style>
.comment-wrap {
  padding: 1rem 20px 1.5rem;
  margin-bottom: 60px;
  border-top: 1px solid #eeeeee;
  background: #fafafa;
}

.comment-item {
  display: flex;
  padding: 8px 0;
}

.comment-itemAvatar {
  width: 32px;
  margin-right: 15px;
}

.comment-itemAvatar .avatarItem {
  width: 32px;
  height: 32px;
}
.comment-item .avatarItem {
  margin-top: 18px;
}

.comment-itemContent {
  flex: 1;
}

.comment-itemBody {
  white-space: pre-line;
  background: #e9e9e9;
  border-radius: 15px;
  padding: 6px 10px;
  max-width: 450px;
  float: left;
}

.comment-itemAuthor {
  font-weight: 500;
  font-size: 0.85rem;
  margin-left: 10px;
  margin-bottom: 0.25rem;
}

.comment-itemMeta {
  margin-left: 1rem;
  color: #888888;
  font-size: 0.9rem;
  margin-top: 0.5rem;
  visibility: hidden;
}

.comment-item:hover .comment-itemMeta {
  visibility: visible;
}

.comment-formTrigger {
  margin-left: 1rem;
  display: none;
}

.comment-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  padding: 4px 20px;
  border-top: 1px solid #e3e3e3;
  z-index: 3;
}

.comment-form .avatarItem {
  margin-top: 0;
}

.comment-form textarea {
  /* padding-top: 8px; */
  resize: none;
}

/* .comment-wrap:not(.comment-form--expanded) .comment-form textarea {
  height: 36px;
}

.comment-wrap.comment-form--expanded {
  margin-bottom: 177px;
}

.comment-wrap.comment-form--expanded .comment-formTrigger {
  display: block;
} */

.activityLog-item {
  display: flex;
  justify-content: space-between;
  padding: 2px 0 2px 47px;
  color: #777777;
}

.activityLog-date,
.comment-date {
  color: #999999;
  font-size: 0.9rem;
  align-self: center;
}

.activityLog-item p {
  font-size: 0.9rem;
}

.activityLog-item p:first-child {
  flex: 1;
  text-align: center;
}

.activityLog-item p:not(:first-child) {
  margin-left: 1rem;
}

.activityLog-item p strong {
  font-weight: 400;
}

.lastComment-wrapper {
  cursor: pointer;
}

.lastComment-wrapper .comment-itemBody {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding-bottom: 4px;
}
</style>
