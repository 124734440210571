<template>
  <div class="box-row relative" :data-field-name="field.fieldName">
    <div class="box-cell justify-between">
      <main class="flex items-center">
        <span class="box-rowLabel" v-if="field.title">
          {{ field.title }}
          <span class="light">({{ label }})</span>
          <span v-if="containerFields" class="light">{{ containerFields }}</span>
        </span>
        <span v-else class="box-rowLabel">
          {{ label }}
          <span v-if="containerFields" class="light">{{ containerFields }}</span>
        </span>
        <fa-icon v-if="hasRights" name="lock" class="ml-2 text-green-600" title="Mit Leserechten versehen" />
        <fa-icon v-if="hasConditions" name="eye-slash" class="ml-2 text-gray-500" title="An Objektkriterien gebunden" />
        <fa-icon v-if="field.mandatory" name="asterisk" class="ml-2 text-gray-500" title="Pflichtfeld" />
        <fa-icon v-if="field.hint" name="info-circle" class="ml-2 text-gray-500" :title="field.hint" />
      </main>
      <nice-label
        v-if="field.exposeType == 'long'"
        small
        key="long"
        label="Nur im Lang-Exposé"
        bg-class="bg-yellow-600"
      />
      <nice-label
        v-else-if="field.exposeType == 'short'"
        small
        key="short"
        label="Nur im Kurz-Exposé"
        bg-class="bg-red-600"
      />
    </div>
    <hover-actions>
      <hover-action v-if="formField && !formField.cantHaveRights" @click="$emit('edit-rights', field)" icon="pencil" />
      <hover-action @click="$emit('remove', field)" icon="trash-alt" class="text-danger" />
    </hover-actions>
  </div>
</template>

<script>
export default {
  props: ["field", "formFields", "group"],
  computed: {
    formField() {
      return this.formFields[this.field.fieldName]
    },
    label() {
      return this.formField ? this.formField.label : `Kaputtes Feld (${this.field.fieldName})`
    },
    hasRights() {
      return (
        this.field.readBrokerIds.length > 0 ||
        this.field.writeBrokerIds.length > 0 ||
        this.field.readDepartmentIds.length > 0 ||
        this.field.writeDepartmentIds.length > 0
      )
    },
    containerFields() {
      if (
        this.field.fieldName !== "container" ||
        !(this.field.containerFieldNames && this.field.containerFieldNames.length)
      )
        return ""
      return `(${this.field.containerFieldNames.join(", ")})`
    },
    hasConditions() {
      const {
        allowedMarketingType,
        allowedRsTypes = [],
        propertyStatusIds = [],
        countries = [],
        clientGroupIds = [],
      } = this.field
      return (
        allowedMarketingType ||
        allowedRsTypes.length > 0 ||
        propertyStatusIds.length > 0 ||
        countries.length > 0 ||
        clientGroupIds.length > 0
      )
    },
  },
}
</script>

<style scoped>
.box-row {
  background: #f5f5f5;
  padding: 0.2rem 0.6rem;
  border: 1px solid #dddddd;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  cursor: move;
}
.box-cell {
  flex: 1;
  display: flex;
  align-items: center;
}
</style>
