<template>
  <div class="searchFilter-item" :class="{ disabled: filter.disabled }">
    <a href="#" @click.prevent.stop="$emit('remove', filter.key)" class="searchFilter-remove">
      <fa-icon name="times" />
    </a>
    <div class="searchFilter-column">
      <nice-select
        grouped
        :model-value="columnValue"
        @update:model-value="columnValue = $event"
        @change="resetFields"
        :options="groupedColumns"
        :clearable="false"
        fixed-position
      />
    </div>
    <div class="searchFilter-operator">
      <p v-if="column.type == 'Boolean' || (column.type == 'Array' && column.specific)">
        {{ $t("dynamicFilter.boolean") }}
      </p>
      <nice-select
        v-else
        :model-value="operator"
        @update:model-value="operatorChanged"
        :options="operators"
        :clearable="false"
        fixed-position
      />
    </div>
    <div
      v-if="operator || column.type == 'Boolean'"
      :class="{
        'searchFilter-value-hidden': hideDateSelect,
      }"
      class="searchFilter-value"
    >
      <nice-switch v-if="column.type == 'Boolean'" class="mt-2" v-model="booleanValue" />
      <nice-select
        v-if="column.type == 'Array' && !column.array && !column.remote && operator == 'is' && !column.dboptions"
        :nested="true"
        :model-value="listValue"
        @update:model-value="listValue = $event"
        :options="dropdownOptions"
        fixed-position
      />
      <db-select
        multiple
        v-if="column.type == 'Array' && column.dboptions && Array.isArray(listValue)"
        v-model="listValue"
        :collection="column.dboptions"
        :additional-options="additionalOptions"
        fixed-position
        show-disabled-regularly
      />
      <div class="w-100" v-if="column.type == 'Array' && column.dboptions && !Array.isArray(listValue)">
        <db-select
          v-model="listValue"
          :collection="column.dboptions"
          :additional-options="additionalOptions"
          show-disabled-regularly
          fixed-position
        />
      </div>
      <nice-select
        filterable
        multiple
        :model-value="listValue"
        @update:model-value="listValue = $event"
        v-if="column.type == 'Array' && (operator != 'is' || column.remote || column.array) && !column.dboptions"
        :clearable="false"
        :options="dropdownOptions"
        :remote="!!column.remote"
        :remote-method="fetchData"
        :placeholder="column.label"
        fixed-position
      />
      <nice-date-picker
        v-if="
          column.type == 'Date' &&
          operator != 'dgte' &&
          operator != 'dlte' &&
          operator != 'lastXMonths' &&
          operator != 'lastXDays'
        "
        fixed-position
        v-model="dateValue"
        :placeholder="$t(`${pluralizedFilterType}.formFields.${column.value}`)"
      />
      <nice-input
        size="small"
        v-if="
          column.type == 'Number' ||
          operator == 'dgte' ||
          operator == 'dlte' ||
          operator == 'lastXMonths' ||
          operator == 'lastXDays'
        "
        type="number"
        v-model="textValue"
      />
      <nice-input size="small" v-if="column.type == 'String'" v-model="textValue" />
    </div>
  </div>
</template>

<script>
import { getPluralizedName } from "@/utils/get-pluralized-name"
import { debounce } from "debounce"

const textOperators = [
  {
    name: "tIs",
    id: "is",
  },
  {
    name: "tIsnt",
    id: "isnt",
  },
  {
    name: "missing",
    id: "missing",
  },
  {
    name: "exists",
    id: "exists",
  },
]

const arrayOperators = [
  {
    name: "aIs",
    id: "is",
  },
  {
    name: "aContains",
    id: "contains",
  },
  {
    name: "aDoesNotContain",
    id: "doesNotContain",
  },
  {
    name: "missing",
    id: "missing",
  },
  {
    name: "exists",
    id: "exists",
  },
]

const numberOperators = [
  {
    name: "nIs",
    id: "is",
  },
  {
    name: "nIsnt",
    id: "isnt",
  },
  {
    name: "nLt",
    id: "lt",
  },
  {
    name: "nGt",
    id: "gt",
  },
  {
    name: "missing",
    id: "missing",
  },
  {
    name: "exists",
    id: "exists",
  },
]

const dateOperators = [
  {
    name: "daLt",
    id: "lt",
  },
  {
    name: "daGt",
    id: "gt",
  },
  {
    name: "daDgte",
    id: "dgte",
  },
  {
    name: "daDlte",
    id: "dlte",
  },
  {
    name: "missing",
    id: "missing",
  },
  {
    name: "exists",
    id: "exists",
  },
  {
    name: "thisMonth",
    id: "thisMonth",
  },
  {
    name: "lastMonth",
    id: "lastMonth",
  },
  {
    name: "thisYear",
    id: "thisYear",
  },
  {
    name: "lastYear",
    id: "lastYear",
  },
  {
    name: "lastXDays",
    id: "lastXDays",
  },
  {
    name: "lastXMonths",
    id: "lastXMonths",
  },
]

const dobOperators = [
  {
    name: "today",
    id: "today",
  },
  {
    name: "doDgte",
    id: "dgte",
  },
  {
    name: "doDlte",
    id: "dlte",
  },
  {
    name: "missing",
    id: "missing",
  },
  {
    name: "exists",
    id: "exists",
  },
]

const verifyChange = (oldValue, newValue) => {
  newValue = typeof newValue === "object" ? JSON.stringify(newValue) : newValue
  oldValue = typeof oldValue === "object" ? JSON.stringify(oldValue) : oldValue
  return oldValue !== newValue
}

export default {
  props: {
    columns: {
      type: Array,
      required: true,
    },
    filter: {
      type: Object,
      required: true,
    },
    filterFieldNames: {
      type: Array,
      required: true,
    },
    filterType: {
      type: String,
    },
  },

  data(props) {
    return {
      columnValue: props.columns.length > 0 ? props.columns[0].value : nil,
      operator: null,
      textValue: null,
      dateValue: null,
      listValue: null,
      remoteData: [],
      booleanValue: false,
    }
  },

  methods: {
    resetFields() {
      this.operator = this.operators.length > 0 ? this.operators[0].id : null
      this.textValue = null
      this.dateValue = null
      this.listValue = !!this.column.array || this.operator != "is" ? [] : null
      this.booleanValue = false
    },

    fetchData: debounce(function (q) {
      if (!q) return

      const url = this.column && this.column.remote === "property" ? "/portfolio/properties" : "/contacts/clients"

      this.$axios.get(`${url}?q=${q}`).then(({ data }) => {
        let newRemoteData = this.listValue?.length ? this.remoteData.filter(d => this.listValue.includes(d.id)) : []

        const ids = newRemoteData.map(r => r.id)

        this.remoteData = newRemoteData.concat(data.data.filter(d => !ids.includes(d.id)))
      })
    }),

    fetchInitialData() {
      const url =
        this.column && this.column.remote === "property"
          ? "/portfolio/properties?property_ids="
          : "/contacts/clients?client_ids="
      this.listValue = (this.listValue || []).map(id => parseInt(id))

      this.$axios.get(`${url}${this.listValue.join(",")}`).then(({ data }) => (this.remoteData = data.data))
    },

    setWatchers() {
      this.$watch(
        "columnValue",
        (oldValue, newValue) => verifyChange(oldValue, newValue) && this.$emit("update", this.esData)
      )
      this.$watch(
        "operator",
        (oldValue, newValue) => verifyChange(oldValue, newValue) && this.$emit("update", this.esData)
      )
      this.$watch(
        "value",
        (oldValue, newValue) => verifyChange(oldValue, newValue) && this.$emit("update", this.esData)
      )
    },

    operatorChanged(val) {
      this.operator = val
      if (this.column.type == "Array") {
        this.listValue = !!this.column.array || this.operator != "is" ? [] : null
      }
    },
    prepareOperators(operators) {
      return operators.filter(o =>
        this.column.filterOptions && this.column.filterOptions.length ? !this.column.filterOptions.includes(o.id) : o
      )
    },
  },

  computed: {
    pluralizedFilterType() {
      return getPluralizedName(this.filterType)
    },
    groupedColumns() {
      return Object.entries(_.groupBy(this.columns, "group")).map(([k, v]) => ({
        id: k,
        name: v.some(o => o.isCustomField) ? k : this.$t(`${this.pluralizedFilterType}.formFieldsGroups.${k}`),
        children: v
          .map(({ value, label, type, nested }) => ({
            id: value,
            name: label || this.$t(`${this.pluralizedFilterType}.formFields.${value}`),
            type,
            nested,
            disabled: this.filterFieldNames.indexOf(value) > -1 && nested && type != "Date" && type != "Number",
          }))
          .sort((a, b) => (a.name > b.name ? 1 : -1)),
      }))
    },

    additionalOptions() {
      return this.column.dboptions === "activeBrokers"
        ? [{ id: "{{ broker_id }}", internalName: this.$t("todos.currentUser") }]
        : undefined
    },

    dropdownOptions() {
      if (this.column.type !== "Array") return []
      if (this.column.remote) return this.remoteData
      if (this.column.dboptions) return this.$db.shopData[this.column.dboptions]
      return this.column.options
    },

    column() {
      return this.columns.find(col => col.value === this.columnValue)
    },

    operators() {
      if (!this.column || this.column.type === "Boolean") return []

      switch (this.column.type) {
        case "String":
          return this.prepareOperators(textOperators.map(o => ({ ...o, name: this.$t(`dynamicFilter.${o.name}`) })))
        case "Array":
          return this.prepareOperators(arrayOperators.map(o => ({ ...o, name: this.$t(`dynamicFilter.${o.name}`) })))
        case "Number":
          return this.prepareOperators(numberOperators.map(o => ({ ...o, name: this.$t(`dynamicFilter.${o.name}`) })))
        case "Date":
          return this.column.value === "dob"
            ? this.prepareOperators(dobOperators.map(o => ({ ...o, name: this.$t(`dynamicFilter.${o.name}`) })))
            : this.prepareOperators(dateOperators.map(o => ({ ...o, name: this.$t(`dynamicFilter.${o.name}`) })))
        default:
          return this.prepareOperators(textOperators.map(o => ({ ...o, name: this.$t(`dynamicFilter.${o.name}`) })))
      }
    },

    value() {
      if (!this.column && this.column !== "0") {
        return null
      }
      switch (this.column.type) {
        case "String":
          return this.textValue
        case "Array":
          return this.listValue
        case "Number":
          return parseFloat(this.textValue)
        case "Date":
          if (this.operator === "lt" || this.operator === "gt") {
            return this.dateValue
          } else if (
            this.operator === "dlte" ||
            this.operator === "dgte" ||
            this.operator === "lastXMonths" ||
            this.operator === "lastXDays"
          ) {
            return this.textValue
          }
          break
        case "Boolean":
          return this.booleanValue
        default:
          return null
      }
    },

    esData() {
      return {
        key: this.filter.key,
        type: this.column.type,
        column: this.columnValue,
        operator:
          this.column.type === "Boolean" || (this.column.type === "Array" && this.column.specific)
            ? "is"
            : this.operator,
        value: this.value,
        nested: this.column.nested,
        fieldName: this.column.field || this.column.value,
        params: this.column.params,
        distinct: this.column.distinct,
        null: this.column.null,
        range: this.column.range,
        renderedQuery: this.column.render?.(
          this.value,
          this.column.type === "Boolean" || (this.column.type === "Array" && this.column.specific)
            ? "is"
            : this.operator
        ),
      }
    },
    hideDateSelect() {
      const conditions = ["exists", "missing", "today", "thisMonth", "lastMonth", "thisYear", "lastYear"]
      return conditions.includes(this.operator)
    },
  },

  mounted() {
    if (this.filter.column) this.columnValue = this.filter.column
    this.operator = this.filter.operator || this.operators[0].id

    if (this.filter.type === "String" || this.filter.type === "Number") {
      this.textValue = this.filter.value
    } else if (this.filter.type === "Date") {
      if (
        this.operator === "dlte" ||
        this.operator === "dgte" ||
        this.operator === "lastXMonths" ||
        this.operator === "lastXDays"
      ) {
        this.textValue = this.filter.value
      } else {
        this.dateValue = this.filter.value
      }
    } else if (this.filter.type === "Boolean") {
      this.booleanValue = this.filter.value
    } else if (this.filter.type === "Array") {
      this.listValue = this.filter.value
      if (this.column.remote) {
        this.fetchInitialData()
      }
    }

    this.$nextTick(() => this.setWatchers())
  },
}
</script>

<style scoped>
select.form-control {
  min-height: auto;
  height: 30px;
  border-radius: 3px;
  font-size: 13px;
}
.searchFilter-column.nice-select::after {
  top: 4px;
}
</style>

<style>
.label-fix > .el-select__tags > span > .el-tag--info {
  display: flex;
  align-items: center;
  overflow: hidden;
}
.label-fix > .el-select__tags > span > .el-tag--info > .el-select__tags-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
