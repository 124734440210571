<script lang="ts" setup>
import { ref, computed, toRefs } from "vue"
import { useI18n } from "vue-i18n"

const visible = defineModel({
  required: true,
  default: false,
})

const props = withDefaults(
  defineProps<{
    correctValue: number
    title: string
    description: string
    placeholder: string
    request: () => Promise<void>
  }>(),
  {
    correctValue: 0,
    title: "",
    description: "",
    placeholder: "",
    request: async () => undefined,
  }
)

const { t } = useI18n()
const { correctValue, title, description, placeholder, request } = toRefs(props)

const securityCheckField = ref<number | null>(null)
const submitting = ref(false)

const emit = defineEmits(["update:modelValue", "submitted"])

const formattedDescription = computed(() => {
  return description.value.replaceAll(/\{\d+\}/g, String(correctValue.value))
})

const confirmSubmit = () => {
  if (correctValue.value == securityCheckField.value) {
    submit()
  } else {
    App.alert(t("numberConfirmDialog.error"))
  }
}

const submit = async () => {
  try {
    submitting.value = true

    await request.value()

    emit("submitted")
  } catch (error) {
    App.alert(error)
  } finally {
    submitting.value = false
  }
}

const closedCallback = () => {
  emit("update:modelValue", false)
}
</script>

<template>
  <nice-dialog :model-value="visible" :title="title" width="400px" @close="closedCallback" append-to-body>
    <form @submit.prevent="confirmSubmit">
      <label style="white-space: pre-wrap; word-break: keep-all">{{ formattedDescription }}</label>
      <br />
      <br />
      <nice-input
        size="small"
        type="number"
        v-model.number="securityCheckField"
        :placeholder="placeholder"
        class="mb-2"
      />
      <slot />
    </form>
    <template #footer>
      <nice-button
        :disabled="correctValue != securityCheckField"
        type="danger"
        :loading="submitting"
        @click="confirmSubmit"
        class="w-100 h-12"
      >
        {{ $t("numberConfirmDialog.confirm") }}
      </nice-button>
    </template>
  </nice-dialog>
</template>
