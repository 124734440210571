<template>
  <div>
    <dropdown v-if="selectable && $db.broker.canEditUserStatus">
      <template v-slot:reference>
        <tooltip :content="availability.label" class="flex justify-center">
          <fa-icon :name="availability.icon" class="cursor-pointer" />
        </tooltip>
      </template>
      <dropdown-section>
        <dropdown-item
          v-for="item in availabilities"
          :key="item.id"
          :title="item.label"
          @click="updateStatus(item.id)"
        />
      </dropdown-section>
    </dropdown>
    <tooltip v-else-if="availability" :content="availability.label">
      <fa-icon :name="availability.icon" />
    </tooltip>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue"

export default defineComponent({
  props: {
    broker: {
      type: Object as PropType<any>,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    async updateStatus(key: any): Promise<void> {
      const payload = { availability: key }
      try {
        await this.$api.update("Broker", this.broker.id, payload, `{ id, ${Object.keys(payload)} }`)
        const updatedBroker = { ...this.broker, availability: key }
        this.$emit("update-broker", updatedBroker)
      } catch (err) {
        App.alert(this.$t("brokers.error"))
        console.error(err)
      }
    },
  },
  computed: {
    availabilities(): any {
      const options = [
        { id: "Büro", icon: "building", label: this.$t("header.availabilities.office") },
        { id: "Home Office", icon: "home", label: this.$t("header.availabilities.homeOffice") },
        { id: "Im Urlaub", icon: "tree-palm", label: this.$t("header.availabilities.vacation") },
        { id: "Krank", icon: "thermometer", label: this.$t("header.availabilities.sick") },
      ] as any

      if (this.selectable && this.$db.broker.canEditUserStatus)
        options.push({ id: null, icon: "plus", label: this.$t("header.availabilities.notSet") })

      return options
    },
    availability(): any {
      return this.availabilities.find(a => a.id == this.broker.availability)
    },
  },
})
</script>
