<template>
  <div ref="container" class="toastui-vue-calendar" />
</template>
<script>
import Calendar from "@toast-ui/calendar"
import "@toast-ui/calendar/dist/toastui-calendar.css"

const EventNames = [
  "selectDateTime",
  "beforeCreateEvent",
  "beforeUpdateEvent",
  "beforeDeleteEvent",
  "afterRenderEvent",
  "clickDayName",
  "clickEvent",
  "clickMoreEventsBtn",
  "clickTimezonesCollapseBtn",
]

export default {
  name: "NiceCalendar",
  props: {
    view: String,
    useFormPopup: {
      type: Boolean,
      default: () => undefined,
    },
    useDetailPopup: {
      type: Boolean,
      default: () => undefined,
    },
    isReadOnly: {
      type: Boolean,
      default: () => undefined,
    },
    usageStatistics: {
      type: Boolean,
      default: () => false,
    },
    eventFilter: Function,
    week: Object,
    month: Object,
    gridSelection: {
      type: [Object, Boolean],
      default: () => undefined,
    },
    timezone: Object,
    theme: Object,
    template: Object,
    calendars: Array,
    events: Array,
  },
  data() {
    return {
      calendarInstance: null,
    }
  },
  emits: EventNames,
  watch: {
    view(value) {
      this.calendarInstance.changeView(value)
    },
    useFormPopup(value) {
      this.calendarInstance.setOptions({ useFormPopup: value })
    },
    useDetailPopup(value) {
      this.calendarInstance.setOptions({ useDetailPopup: value })
    },
    isReadOnly(value) {
      this.calendarInstance.setOptions({ isReadOnly: value })
    },
    eventFilter(value) {
      this.calendarInstance.setOptions({ eventFilter: value })
    },
    week(value) {
      this.calendarInstance.setOptions({ week: value })
    },
    month(value) {
      this.calendarInstance.setOptions({ month: value })
    },
    gridSelection(value) {
      this.calendarInstance.setOptions({ gridSelection: value })
    },
    timezone(value) {
      this.calendarInstance.setOptions({ timezone: value })
    },
    theme(value) {
      this.calendarInstance.setTheme(value)
    },
    template(value) {
      this.calendarInstance.setOptions({ template: value })
    },
    calendars(value) {
      this.calendarInstance.setCalendars(value)
    },
    events(value) {
      this.calendarInstance.clear()
      this.calendarInstance.createEvents(value)
    },
  },
  mounted() {
    this.calendarInstance = new Calendar(this.$refs.container, {
      defaultView: this.view,
      useFormPopup: this.useFormPopup,
      useDetailPopup: this.useDetailPopup,
      isReadOnly: this.isReadOnly,
      usageStatistics: this.usageStatistics,
      eventFilter: this.eventFilter,
      week: this.week,
      month: this.month,
      gridSelection: this.gridSelection,
      timezone: this.timezone,
      theme: this.theme,
      template: this.template,
      calendars: this.calendars,
    })
    this.addEventListeners()
    this.calendarInstance.createEvents(this.events)
  },
  beforeDestroy() {
    this.calendarInstance.off()
    this.calendarInstance.destroy()
  },
  methods: {
    addEventListeners() {
      EventNames.forEach(eventName => {
        this.calendarInstance.on(eventName, (...args) => {
          this.$emit(eventName, ...args)
        })
      })
    },
    getRootElement() {
      return this.$refs.container
    },
    getInstance() {
      return this.calendarInstance
    },
  },
}
</script>

<style>
.calendar-wrapper {
  flex: 1;
  height: 100%;
  overflow: auto;
}

.toastui-calendar-day-name-item > span {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: initial;
}

.toastui-calendar-day-name__date {
  font-size: 18px;
}

.toastui-calendar-day-name__name {
  font-weight: 400;
  font-size: 10px;
  order: -1;
  color: #777;
  margin: 3px 0;
  text-transform: uppercase;
}

.toastui-calendar-weekly-view {
  display: block;
  overflow: hidden;
  max-height: calc(100% - 4px);
}

.toastui-calendar-column .toastui-calendar-grid-selection {
  display: none;
}

.toastui-calendar-grid-selection-label {
  display: none;
}

.toastui-calendar-event-time {
  overflow: visible;
}

/* .toastui-calendar-template-goingDuration {}
.toastui-calendar-template-comingDuration {}
.toastui-calendar-travel-time {} */

.tui-full-calendar-weekly-view {
  display: block;
  overflow: hidden;
  max-height: calc(100% - 4px);
}
.tui-full-calendar-monthly-view {
  font-size: 11px;
}

.toastui-calendar-weekday-event-title {
  font-weight: 300;
}

.toastui-calendar-day-view .tui-full-calendar-monthly-view {
  display: none;
}
.toastui-calendar-week-view .tui-full-calendar-monthly-view {
  display: none;
}
.toastui-calendar-month .tui-full-calendar-weekly-view {
  display: none;
}

.toastui-calendar-panel.toastui-calendar-allday {
  height: 30px !important;
}
</style>
